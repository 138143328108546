import debounce from 'lodash/debounce';

/**
 * Adds query params to sro tickets links, in mobile view, to select tab in sro where sro choose seating
 */
export default () => {
  const mobileBreakpoint = 600;
  const mobileParams = 'type=ba&noredir&selMode=ba'; // These params selects tab in sro seating plan where sro chooses best seating. This is use for mobile.
  const allowedNodeNames = [
    'BODY',
    'DIV',
    'SECTION',
    'UL',
    'LI',
    'ARTICLE',
    'ASIDE',
  ];

  const ticketLinkSelectors = [
    'a.buy[href*="billett.detnorsketeatret.no/nn-NO/events"]', // Prod sro ticket link
    'a.buy[href*="billett.detnorsketeatret.no/loader.aspx/?target=hall.aspx"]', // Prod sro ticket link with coupon code
    'a.buy[href*="seatgeekenterprise.co.uk/nn-NO/events"]', // stage sro ticket link
    'a.buy[href*="seatgeekenterprise.co.uk/loader.aspx/?target=hall.aspx"]', // stage sro ticket link with coupon code
  ].join(', ');

  let buttons = [];

  // Adds ticket link buttons to monitor
  const addButtons = node => {
    if (allowedNodeNames.indexOf(node.nodeName) >= 0) {
      const nodeList = node.querySelectorAll(ticketLinkSelectors);

      Array.from(nodeList).forEach(node => {
        if (buttons.indexOf(node) < 0) {
          buttons.push(node);
        }
      });
    }
  };

  // Remove ticket link buttons to monitor
  const removeButtons = node => {
    if (allowedNodeNames.indexOf(node.nodeName) >= 0) {
      const nodeList = node.querySelectorAll(ticketLinkSelectors);
      const remove = Array.from(nodeList);
      buttons = buttons.filter(node => {
        return remove.indexOf(node) < 0;
      });
    }
  };

  // Listen for html changes
  const observer = new MutationObserver(mutationsList => {
    for (const mutation of mutationsList) {
      if (mutation.type == 'childList') {
        if (mutation.removedNodes.length > 0) {
          mutation.removedNodes.forEach(removeButtons);
          executeButtonParams();
        }

        if (mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach(addButtons);
          executeButtonParams();
        }
      }
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });

  // Add query params to ticket links
  const addParams = () => {
    const escapedMobileParams = encodeURIComponent(`&${mobileParams}`);
    buttons.forEach(node => {
      if (node.search.indexOf('?target=hall.aspx') > -1) {
        if (node.search.indexOf(escapedMobileParams) < 0) {
          node.search += escapedMobileParams;
        }
      } else {
        if (node.search.indexOf(mobileParams) < 0) {
          node.search += mobileParams;
        }
      }
    });
  };

  // Remove query params to ticket links
  const removeParams = () => {
    const escapedMobileParams = encodeURIComponent(`&${mobileParams}`);
    buttons.forEach(node => {
      if (node.search.indexOf('?target=hall.aspx') > -1) {
        node.search = node.search.replace(escapedMobileParams, '');
      } else {
        node.search = node.search.replace(mobileParams, '');
      }
    });
  };

  // Check if mobile view
  const executeButtonParams = () => {
    if (window.innerWidth > mobileBreakpoint) {
      removeParams();
    } else {
      addParams();
    }
  };

  const onResize = debounce(executeButtonParams, 100);

  addButtons(document.body);

  window.addEventListener('resize', onResize);
  executeButtonParams();
};
