/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the Profile component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Profile({ title }) {
  return (
    <span className="svg svg-square svg-profile">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0"
          y="0"
          viewBox="0 0 25 25"
          enableBackground="new 0 0 25 25"
          xmlSpace="preserve"
          aria-hidden="true"
        >
          <title>{title}</title>
          <path d="M16.2 12.4c1.3-1 2.1-2.6 2.1-4.3 0-3.1-2.6-5.6-5.7-5.6S6.8 5 6.8 8.1c0 1.7 0.8 3.3 2.1 4.3 -3.7 1.4-6.3 5-6.3 9.1h2c0-4.3 3.6-7.8 8-7.8s8 3.5 8 7.8h2C22.5 17.4 19.9 13.9 16.2 12.4zM8.8 8.1c0-2 1.7-3.6 3.7-3.6s3.7 1.6 3.7 3.6 -1.7 3.6-3.7 3.6S8.8 10.1 8.8 8.1z" />
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Profile.propTypes = {
  title: PropTypes.string.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Profile.defaultProps = {
  title: 'Profile',
};

export default Profile;
