/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the Exit component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Exit({ title, ...props }) {
  return (
    <span className="svg svg-square svg-exit">
      <span>
        <svg
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Layer_1"
          x="0"
          y="0"
          viewBox="0 0 25 25"
          xmlSpace="preserve"
          enableBackground="new 0 0 25 25"
          {...props}
        >
          <title>{title}</title>
          <path d="M18.5 5.1l1.4 1.4 -6 6 6 6 -1.4 1.4 -6-6 -6 6 -1.4-1.4 6-6 -6-6 1.4-1.4 6 6L18.5 5.1z" />
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Exit.propTypes = {
  title: PropTypes.string.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Exit.defaultProps = {
  title: 'Exit',
};

export default Exit;
