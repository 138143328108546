import { useRef, useEffect } from 'react';
import keyboard from 'keymaster';

let counter = -1;

keyboard.filter = e => {
  if (e.which === 27) {
    return true;
  }
  var tagName = (event.target || event.srcElement).tagName;
  return !(tagName == 'INPUT' || tagName == 'SELECT' || tagName == 'TEXTAREA');
};

export default (name = 'keys', active = false, keyDef = {}) => {
  const guid = useRef(null);
  const keys = useRef(keyDef);
  useEffect(() => {
    counter += 1;
    guid.current = `${name}-${counter}`;

    return function cleanup() {
      counter -= 1;
    };
  }, [name]);

  useEffect(() => {
    if (guid.current !== null) {
      Object.keys(keys.current).forEach(key => {
        keyboard(`${key}`, guid.current, keys.current[key]);
      });
    }

    return function cleanup() {
      keyboard.deleteScope(guid.current);
    };
  }, [guid, keys]);

  useEffect(() => {
    if (active) {
      keyboard.setScope(guid.current);
    } else {
      if (keyboard.getScope() === guid.current) {
        keyboard.setScope('all');
      }
    }
  }, [active, guid]);
};
