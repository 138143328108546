import 'intersection-observer';
import { useState, useEffect } from 'react';
export default (ref, settings = {}) => {
  const [inview, setInview] = useState(false);

  const { root, rootMargin, threshold } = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
    ...settings,
  };

  const [observer, setObserver] = useState(null);

  const onIntersect = entries => {
    entries.forEach(({ target, isIntersecting }) => {
      if (target === ref.current) {
        setInview(isIntersecting);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, {
      root,
      rootMargin,
      threshold,
    });

    setObserver(observer);

    return function cleanup() {
      observer.disconnect();
      setObserver(null);
    };
  }, [root, rootMargin, threshold]);

  useEffect(() => {
    if (observer) {
      if (ref.current && observer) {
        observer.observe(ref.current);
      }
    }
    return function cleanup() {
      if (observer) {
        if (ref.current && observer) {
          observer.unobserve(ref.current);
        }
      }
    };
  }, [observer]);

  return inview;
};
