(() => {
  let timeout = null;
  let prev = 0;

  function onScroll() {
    clearTimeout(timeout);
    const currentScroll =
      document.documentElement.scrollTop || document.body.scrollTop;

    const up = currentScroll - prev < 0;

    if (currentScroll > 120) {
      if (up) {
        document.body.classList.add('menu-fixed');
        document.body.classList.remove('menu-hidden');
      } else {
        document.body.classList.add('menu-hidden');
      }
    } else {
      timeout = setTimeout(() => {
        document.body.classList.remove('menu-fixed', 'menu-hidden');
      }, 60);
    }

    prev = currentScroll;
  }

  window.addEventListener('scroll', onScroll);
  onScroll();
})();
