/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, {
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import From from 'components/Form/Form';
import Loader from 'components/Loader/Loader';
import api from 'utils/api';
import scrollTo from 'utils/scrollTo';
import useCsrf from 'effects/csrf';
import './Newsletter.scss';

/**
 * This is the Newsletter component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */

const Newsletter = forwardRef(function Newsletter(
  {
    disabled,
    inactive,
    action,
    form,
    receipt: receiptText,
    onChange,
    onLoading,
    onError: onErrorCallback,
    onSuccess,
  },
  ref
) {
  const containerRef = useRef(null);
  const fromRef = useRef(null);
  const [csrf, csrfActions] = useCsrf();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [receipt, setReceipt] = useState(false);

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (fromRef.current) {
        fromRef.current.focus();
      }
    },
  }));

  function onError() {
    if (containerRef.current) {
      const firstFieldError = containerRef.current.querySelector(
        '.field-row.with-error'
      );
      if (firstFieldError) {
        scrollTo(firstFieldError);
      }
    }
  }

  function onSubmit(data) {
    setError(null);
    setLoading(true);
    onLoading(true);
    setReceipt(false);
    api(action, 'POST', {
      [csrf.name]: csrf.value,
      ...data,
    })
      .then(() => {
        setLoading(false);
        onLoading(false);
        onSuccess();
        setReceipt(true);
        fromRef.current.reset();
        csrfActions.refresh();
      })
      .catch(({ error }) => {
        setLoading(false);
        onLoading(false);
        setError(error);
        onErrorCallback(error);
        csrfActions.refresh();
      });
  }

  function renderLoader() {
    if (!loading) return null;

    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  }

  function renderError() {
    if (!error) return null;

    return <div className="global-error">{error}</div>;
  }

  function renderReceipt() {
    if (!receipt) return null;

    return (
      <div
        className="receipt"
        dangerouslySetInnerHTML={{ __html: receiptText }}
      />
    );
  }

  return (
    <div ref={containerRef} className="newsletter">
      <From
        ref={fromRef}
        {...form}
        disabled={loading || disabled}
        inactive={inactive}
        onFocus={() => {
          setReceipt(null);
        }}
        onChange={onChange}
        onSubmit={onSubmit}
        onError={onError}
      >
        {renderLoader()}
        {renderError()}
        {renderReceipt()}
      </From>
    </div>
  );
});

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Newsletter.propTypes = {
  action: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  inactive: PropTypes.bool,
  form: PropTypes.object.isRequired,
  receipt: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onLoading: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Newsletter.defaultProps = {
  disabled: false,
  inactive: false,
  form: {},
  receipt: '<p>Thank you for your interest.</p>',
  onChange: () => {},
  onLoading: () => {},
  onError: () => {},
  onSuccess: () => {},
};

export default Newsletter;
