export function attributeNameToPropertyName(attributeName) {
  return attributeName
    .replace(/^(x|data)[-_:]/i, '')
    .replace(/[-_:](.)/g, (x, chr) => chr.toUpperCase());
}

export function parseAttributeValue(value) {
  if (!value) {
    return null;
  }

  if (value === 'true') return true;
  if (value === 'false') return false;

  // Support attribute values with newlines
  let newValue = value.replace(/[\n\r]/g, '');

  const pointerRegexp = /^{.*?}$/i;
  const jsonRegexp = /^{{2}.*}{2}$/;
  const jsonArrayRegexp = /^{\[.*\]}$/;

  const pointerMatches = newValue.match(pointerRegexp);
  const jsonMatches =
    newValue.match(jsonRegexp) || newValue.match(jsonArrayRegexp);

  if (jsonMatches) {
    newValue = JSON.parse(jsonMatches[0].replace(/^{|}$/g, ''));
  } else if (pointerMatches) {
    /* eslint-disable no-eval */
    newValue = eval(pointerMatches[0].replace(/[{}]/g, ''));
  }

  return newValue;
}

export function getProps(node) {
  const props = {};

  for (let i = 0; i < node.attributes.length; i++) {
    const attribute = node.attributes[i];
    const name = attributeNameToPropertyName(attribute.name);
    props[name] = parseAttributeValue(attribute.value);
  }

  props.container = node;

  return props;
}

export function getChildren(node) {
  const fragment = document.createDocumentFragment();
  if (node.childNodes.length) {
    Array.prototype.forEach.call(node.childNodes, node => {
      fragment.appendChild(node.cloneNode(true));
    });
  }
  return fragment;
}

export default {
  attributeNameToPropertyName,
  parseAttributeValue,
  getProps,
  getChildren,
};
