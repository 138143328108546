/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useState, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import Field from './Field/Field';

function getDefaultState(fields) {
  const state = {};
  fields.forEach(field => {
    if (typeof field.value === 'undefined') {
      if (field.type === 'choices' && field.multiple) {
        state[field.id] = [];
      } else {
        state[field.id] = '';
      }
    } else if (Array.isArray(field.value)) {
      state[field.id] = [...field.value];
    } else if (typeof field.value === 'object') {
      state[field.id] = { ...field.value };
    } else {
      state[field.id] = field.value;
    }
  });

  return state;
}

function getDefaultErrors(fields) {
  const errors = {};
  fields.forEach(field => {
    errors[field.id] = null;
  });

  return errors;
}

/**
 * This is the Form component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Form({
  forwardedRef,
  className,
  fields,
  errors: messages,
  required,
  children,
  submit,
  disabled,
  inactive,
  onChange,
  onFocus,
  onBlur,
  onSubmit,
  onError,
}) {
  const ref = useRef(null);
  const [state, setState] = useState(getDefaultState(fields));
  const [errors, setErrors] = useState(getDefaultErrors(fields));

  function validateField({ id, type, multiple, required }) {
    let error = null;
    const value = typeof value === 'string' ? state[id].trim() : state[id];

    if (required) {
      if (type === 'choices' && multiple) {
        if (value.length === 0) {
          error = 'required';
        }
      } else if (!value) {
        error = 'required';
      }
    }

    if (!error && type === 'email') {
      if (!isEmail(value)) {
        error = 'invalid';
      }
    }

    if (!error && type === 'choices' && multiple) {
      if (!Array.isArray(value)) {
        error = 'invalid';
      }
    }

    setErrors(prev => ({
      ...prev,
      [id]: error,
    }));

    return !error;
  }

  function validate() {
    let valid = true;

    fields.forEach(field => {
      if (!validateField(field)) {
        valid = false;
      }
    });

    return valid;
  }

  function getError({ id, label, messages: fieldMessages = {} }) {
    if (!errors[id]) return null;
    const key = errors[id];
    const componentMessages = {
      ...messages,
      ...fieldMessages,
    };

    if (componentMessages[key]) {
      return componentMessages[key].replace('{field}', label.toLowerCase());
    }
    return key;
  }

  useImperativeHandle(forwardedRef, () => ({
    focus: () => {
      const firstField = ref.current.querySelector(
        'input:not([type="hidden"]), select, textarea'
      );
      if (firstField) {
        firstField.focus();
      }
    },
    reset: () => {
      setState(getDefaultState(fields));
    },
  }));

  function renderRequiredTip() {
    if (!required) return null;

    const hasRequiredField = fields.reduce((prev, current) => {
      if (current.required) {
        return true;
      }
      return prev;
    }, false);

    if (!hasRequiredField) return null;

    return (
      <small
        className="required-tip"
        dangerouslySetInnerHTML={{ __html: required }}
      />
    );
  }

  return (
    <form
      ref={ref}
      className={className}
      disabled={disabled}
      noValidate
      onSubmit={e => {
        e.preventDefault();
        if (validate()) {
          onSubmit(state);
        } else {
          process.nextTick(() => {
            onError();
          });
        }
      }}
    >
      {renderRequiredTip()}
      {fields.map(({ id, ...props }) => (
        <Field
          key={id}
          id={id}
          {...props}
          value={state[id]}
          disabled={disabled}
          tabIndex={inactive ? -1 : null}
          error={getError({ id, ...props })}
          onChange={({ target: { value } }) => {
            setState(state => ({
              ...state,
              [id]: value,
            }));
            setErrors(state => ({
              ...state,
              [id]: null,
            }));
            onChange({
              id,
              ...props,
              value,
            });
          }}
          onFocus={() => {
            setErrors(state => ({
              ...state,
              [id]: null,
            }));
            onFocus({
              id,
              ...props,
            });
          }}
          onBlur={() => {
            validateField({ id, ...props });
            onBlur({
              id,
              ...props,
            });
          }}
        />
      ))}
      {children}
      <button
        type="submit"
        className="ticket-button"
        tabIndex={inactive ? -1 : null}
      >
        <span>{submit}</span>
      </button>
    </form>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Form.propTypes = {
  forwardedRef: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.array.isRequired,
  errors: PropTypes.shape({
    required: PropTypes.string.isRequired,
    invalid: PropTypes.string.isRequired,
  }).isRequired,
  required: PropTypes.string,
  submit: PropTypes.string.isRequired,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  inactive: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Form.defaultProps = {
  forwardedRef: { current: null },
  fields: [],
  errors: {
    required: 'Required {field}',
    invalid: 'Invalid {field}',
  },
  required: null,
  disabled: false,
  inactive: false,
  submit: 'Submit',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onSubmit: () => {},
  onError: () => {},
};

export default React.forwardRef((props, ref) => (
  <Form {...props} forwardedRef={ref} />
));
