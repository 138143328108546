/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the Magnifier component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Magnifier({ title }) {
  return (
    <span className="svg svg-square svg-magnifier">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0"
          y="0"
          viewBox="0 0 25 25"
          enableBackground="new 0 0 25 25"
          xmlSpace="preserve"
          role="img"
        >
          <title>{title}</title>
          <g transform="translate(-716.000000, -310.000000)">
            <g transform="translate(717.000000, 311.000000)">
              <path
                fill="none"
                stroke="#000000"
                strokeWidth="2"
                d="M10 17c3.9 0 7-3.1 7-7s-3.1-7-7-7 -7 3.1-7 7S6.1 17 10 17z"
              />
              <line
                fill="none"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="square"
                x1="15"
                y1="15"
                x2="20"
                y2="20"
              />
            </g>
          </g>
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Magnifier.propTypes = {
  title: PropTypes.string.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Magnifier.defaultProps = {
  title: 'Search',
};

export default Magnifier;
