import { getProps } from 'utils/DOM';

const listeners = [];

function registerDecorator(node, decorate, settings) {
  const props = getProps(node);
  decorate(node, {
    ...settings,
    ...props,
  });
}

function checkAdded(added) {
  if (['STYLE', '#text'].indexOf(added.nodeName) === -1) {
    listeners.forEach(({ selector, decorator, settings }) => {
      try {
        if (typeof added.matches == 'function') {
          if (added.matches(selector)) {
            registerDecorator(added, decorator, settings);
          } else {
            const children = added.querySelectorAll(selector);
            if (children.length) {
              Array.from(children).forEach(node => {
                registerDecorator(node, decorator, settings);
              });
            }
          }
        }
        /* eslint-disable no-empty */
      } catch (e) {}
    });
  }
}

function checkRemoved(removed) {
  if (['STYLE', '#text'].indexOf(removed.nodeName) === -1) {
    listeners.forEach(({ selector }) => {
      try {
        const children = removed.querySelectorAll(selector);

        Array.from(children).forEach(node => {
          if (node.decorator) {
            node.decorator.destroy();
          }
        });

        if (removed.decorator) {
          removed.decorator.destroy();
        }
        /* eslint-disable no-empty */
      } catch (e) {}
    });
  }
}

const observer = new MutationObserver(mutationsList => {
  for (const mutation of mutationsList) {
    if (mutation.type == 'childList') {
      if (mutation.removedNodes.length > 0) {
        mutation.removedNodes.forEach(checkRemoved);
      }

      if (mutation.addedNodes.length > 0) {
        mutation.addedNodes.forEach(checkAdded);
      }
    }
  }
});

observer.observe(document, { childList: true, subtree: true });

export default (selector, decorator, settings = {}) => {
  listeners.push({
    selector,
    decorator,
    settings,
  });

  try {
    const nodes = document.querySelectorAll(selector);
    Array.prototype.forEach.call(nodes, node => {
      registerDecorator(node, decorator, settings);
    });
    /* eslint-disable no-empty */
  } catch (e) {}
};
