import { useRef, useState, useEffect } from 'react';

let counter = 0;

export default (name = 'guid') => {
  const ref = useRef(null);
  const [guid, setGuid] = useState(null);

  useEffect(() => {
    counter += 1;
    ref.current = `${name}-${counter}`;
    return function cleanup() {
      counter -= 1;
    };
  }, [name]);

  useEffect(() => {
    if (ref.current) {
      setGuid(ref.current);
    }
  }, [name]);
  return guid;
};
