import scrollTo from 'utils/scrollTo';

class HomeLink {
  name = 'home-link';
  constructor(node, options = {}) {
    this.props = {
      ...options,
    };

    this.node = node;

    if (!this.node.decorator || this.node.decorator.name !== 'home-link') {
      this.node.decorator = this;
      this.create();
    }
  }

  create = () => {
    if (
      this.node.href.replace(/\/$/, '') ===
      window.location.href.replace(/\/$/, '')
    ) {
      this.node.addEventListener('click', this.onClick);
    }
  };

  destroy = () => {
    this.node.removeEventListener('click', this.onClick);
  };

  onClick = e => {
    e.preventDefault();
    scrollTo(document.body);
  };
}

export default (node, props) => new HomeLink(node, props);
