import FontFaceObserver from 'fontfaceobserver';

const allowedChars = /^[A-ZÆØÅ0-9]+$/;

const fontObserver = new FontFaceObserver('Cormorant Garamond', {
  weight: 400,
});

class Dropcap {
  constructor(node, options = {}) {
    this.props = {
      ...options,
    };

    this.node = node;

    /* prevent double decoration */
    if (this.node.decorator) {
      if (this.node.decorator instanceof Dropcap) {
        this.node.decorator.destroy();
      }
    }

    this.node.decorator = this;
    this.original = this.node.innerHTML;

    fontObserver.load().then(this.create);
  }

  create = () => {
    const firstLetter = this.original.substr(0, 1).toUpperCase();
    const rest = this.original.substr(1);
    if (firstLetter !== '<') {
      let className = 'dropcap';
      if (['J', 'Q'].indexOf(firstLetter) !== -1) {
        className += ' dropcap-big-letter';
      }

      if (firstLetter.match(allowedChars)) {
        this.node.innerHTML = `<span class="${className}">${firstLetter}</span>${rest}`;
      }
    }
  };

  destroy = () => {
    this.node.innerHTM = this.original;
    this.node.decorator = null;
  };
}

export default (node, props) => new Dropcap(node, props);
