class Tracking {
  name = 'tracking';
  constructor(node, options = {}) {
    this.props = {
      ...options,
    };

    this.node = node;

    if (!this.node.decorator || this.node.decorator.name !== 'tracking') {
      if (this.node.dataset.event && this.node.dataset.label) {
        this.node.decorator = this;
        this.create();
      }
    }
  }

  create = () => {
    this.node.addEventListener('click', this.onClick);
  };

  destroy = () => {
    this.node.removeEventListener('click', this.onClick);
  };

  onClick = () => {
    const { event, label } = this.node.dataset;
    window.dataLayer.push({
      event,
      label,
    });
  };
}

export default (node, props) => new Tracking(node, props);
