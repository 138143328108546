import { Inview } from './inview';

class AppearText {
  constructor(node, options = {}) {
    this.props = {
      name: 'appear-text',
      rootMargin: '0px 0px 50px 0px',
      threshold: [0, 0.5, 1],
      selector: 'h1, h2, h3, h4, h5, h6, p, time',
      ...options,
    };
    this.node = node;

    /* prevent double decoration */
    if (this.node.decorator) {
      if (this.node.decorator instanceof AppearText) {
        this.node.decorator.destroy();
      }
    }

    this.node.decorator = this;
    this.items = this.node.querySelectorAll(this.props.selector);

    if (this.items.length) {
      this.create();
    }
  }

  create = () => {
    Array.from(this.items).map(node => {
      return new Inview(node, this.props);
    });
  };

  destroy = () => {
    Array.from(this.items).forEach(node => {
      if (typeof node.destory === 'function') {
        node.destory();
      }
    });
    this.node.decorator = null;
  };
}

export default (node, props) => new AppearText(node, props);
