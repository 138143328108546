/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import useTixlySession from 'effects/useTixlySession';
import SearchIcon from 'components/Icons/Magnifier/Magnifier';
import TicketIcon from 'components/Icons/Ticket/Ticket';
import EpicenterIcon from 'components/Icons/Epicenter/Epicenter';
import MenuIcon from 'components/Icons/Hamburger/Hamburger';
import CloseIcon from 'components/Icons/Exit/Exit';
import ProfileIcon from 'components/Icons/Profile/Profile';
import ProgramIcon from 'components/Icons/Program/Program';
import GiftIcon from 'components/Icons/Gift/Gift';
import Basket from './Basket/Basket';
import Featured from './Featured/Featured';
import Search from './Search/Search';
import 'utils/menuScroll';
import './Menu.scss';

const basketNode = document.getElementById('basket');
const featuredNode = document.getElementById('featured-menu');
const searchNode = document.getElementById('search-hook');

/**
 * This is the Menu component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 */
const Menu = ({
  itixUrl,
  cartLink,
  loginLink,
  searchAction,
  main,
  features,
  labels,
}) => {
  const { profile, client, order, refresh } = useTixlySession(itixUrl);
  const [featured, setFeatured] = useState(false);
  const [search, setSearch] = useState(false);

  const profileLink = profile
    ? `${profile}?returnUrl=${encodeURIComponent(window.location.href)}`
    : null;

  function onToggleSearch() {
    setSearch(search => !search);
    setFeatured(false);
  }

  function onToggleFeatured() {
    setFeatured(featured => !featured);
    setSearch(false);
  }

  function onCloseSearch() {
    setSearch(false);
  }

  function renderBasket() {
    if (!order) return null;
    if (!order.items) return null;
    if (order.items.length < 1) return null;

    if (basketNode) {
      return createPortal(
        <Basket
          {...order}
          client={client}
          cartLink={cartLink}
          loginLink={loginLink}
          registerLink={profileLink}
          profileLink={profileLink}
          labels={labels}
          onRefresh={refresh}
          onExpired={refresh}
        />,
        basketNode
      );
    }

    return (
      <Basket
        {...order}
        client={client}
        cartLink={cartLink}
        loginLink={loginLink}
        registerLink={profileLink}
        profileLink={profileLink}
        labels={labels}
        onRefresh={refresh}
        onExpired={refresh}
      />
    );
  }

  function renderFeatured() {
    if (featuredNode) {
      return createPortal(
        <Featured
          items={features}
          open={featured}
          labels={labels}
          onClose={() => {
            setFeatured(false);
          }}
        />,
        featuredNode
      );
    }

    return (
      <Featured
        items={features}
        open={featured}
        onClose={() => {
          setFeatured(false);
        }}
      />
    );
  }

  function renderSearch() {
    if (!search) return null;
    if (searchNode) {
      return createPortal(
        <Search action={searchAction} onClose={onCloseSearch} />,
        searchNode
      );
    }

    return <Search action={searchAction} onClose={onCloseSearch} />;
  }

  function renderIcon(className, title) {
    if (!className) return null;

    if (className.indexOf('ticket') !== -1) {
      return <TicketIcon title={title} />;
    }

    if (className.indexOf('stream') !== -1) {
      return <EpicenterIcon title={title} />;
    }

    if (className.indexOf('program') !== -1) {
      return <ProgramIcon title={title} />;
    }

    if (className.indexOf('gift') !== -1) {
      return <GiftIcon title={title} />;
    }

    return null;
  }

  function renderClientLink() {
    if (client.id) {
      return (
        <a
          className="profile"
          data-event="Hovudmeny"
          data-label={labels.profile}
          href={`${profile}?returnUrl=${encodeURIComponent(
            window.location.href
          )}`}
        >
          <span>
            <ProfileIcon title={labels.profile} />
            <span className="text">{labels.profile}</span>
          </span>
        </a>
      );
    }

    return (
      <a
        className="profile"
        href={`${loginLink}?returnUrl=${encodeURIComponent(
          window.location.href
        )}`}
      >
        <span>
          <ProfileIcon title={labels.signin} />
          <span className="text">{labels.signin}</span>
        </span>
      </a>
    );
  }

  return (
    <>
      {renderBasket()}
      <div className="global-navigation">
        <nav className="main-navigation" aria-label={labels.menubar}>
          {main.map(({ href, title, className }) => (
            <a key={href} href={href} data-event="Hovudmeny" data-label={title}>
              {renderIcon(className, title)}
              <span
                className="text"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </a>
          ))}
        </nav>
        {renderClientLink()}
        <button
          className="search"
          data-event="Hovudmeny"
          data-label={labels.search}
          onClick={onToggleSearch}
        >
          <span>
            <SearchIcon title={labels.search} />
            <span className="text">{labels.search}</span>
          </span>
        </button>
        <button
          id="menubutton"
          className="toogle"
          onClick={onToggleFeatured}
          aria-haspopup="true"
          aria-controls="submenu"
          aria-expanded={featured}
          data-event="Hovudmeny"
          data-label={featured ? labels.close : labels.menu}
          aria-label={featured ? labels.close : labels.menu}
        >
          <span>
            {featured ? (
              <CloseIcon title={labels.close} aria-hidden="true" />
            ) : (
              <MenuIcon title={labels.menu} aria-hidden="true" />
            )}
            <span className="text">{labels.menu}</span>
          </span>
        </button>
      </div>
      {renderFeatured()}
      {renderSearch()}
    </>
  );
};

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Menu.propTypes = {
  itixUrl: PropTypes.string.isRequired,
  cartLink: PropTypes.string.isRequired,
  loginLink: PropTypes.string.isRequired,
  searchAction: PropTypes.string,
  main: PropTypes.array,
  features: PropTypes.array,
  labels: PropTypes.object,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Menu.defaultProps = {
  itixUrl: '',
  cartLink: '',
  searchAction: '/search',
  main: [],
  features: [],
  labels: {
    menubar: 'Menubar',
    submenu: 'Submenu',
    menu: 'Menu',
    search: 'Large search box',
    tickets: {
      single: '{number} ticket',
      multiple: '{number} tickets',
    },
    merchandise: {
      single: '{number} merchandise',
      multiple: '{number} merchandise',
    },
    signin: 'Sign In',
    register: 'Register',
    total: 'Total',
    toBasket: 'To basket',
    profile: 'My page',
  },
};

export default Menu;
