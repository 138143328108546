/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the Gift component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Gift({ title }) {
  return (
    <span className="svg svg-gift aria-hidden">
      <span>
        <svg
          width="20"
          height="22"
          viewBox="0 0 20 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>{title}</title>
          <rect
            x="1"
            y="6"
            width="18"
            height="15"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path d="M10 7L10 20" stroke="currentColor" strokeWidth="2" />
          <path d="M18 13L2 13" stroke="currentColor" strokeWidth="2" />
          <path d="M6 1L10 5L14 1" stroke="currentColor" strokeWidth="2" />
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Gift.propTypes = {
  title: PropTypes.string.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Gift.defaultProps = {
  title: 'Gift',
};

export default Gift;
