import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import formatDuration from 'date-fns/formatDuration';
import './Time.scss';

export function Time({ expires, onExpired, onRefresh }) {
  const [time, setTime] = useState(expires);
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  const hours = Math.floor(time / 3600);

  useEffect(() => {
    setTime(expires);
  }, [expires]);

  useEffect(() => {
    let timeout = null;

    if (time > 0) {
      timeout = setTimeout(() => {
        setTime(time => {
          const next = time - 1;
          if (next < 0) {
            return 0;
          }

          return next;
        });
      }, 1000);
    } else {
      onExpired();
    }

    return function cleanup() {
      clearTimeout(timeout);
    };
  }, [time]);

  useEffect(() => {
    let interval = null;
    if (expires > 10) {
      interval = setInterval(function() {
        onRefresh();
      }, 10000);
    }
    return function cleanup() {
      clearInterval(interval);
    };
  }, [expires]);

  if (expires <= 0) {
    return null;
  }

  if (time <= 0) {
    return null;
  }

  return (
    <div className="time">
      {formatDuration({ hours, minutes, seconds }, { zero: false })}
    </div>
  );
}

Time.propTypes = {
  expires: PropTypes.number.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onExpired: PropTypes.func.isRequired,
};

Time.defaultProps = {
  expires: 0,
  onRefresh: () => {},
  onExpired: () => {},
};

export default Time;
