/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { forwardRef, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import throttle from 'lodash/throttle';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import 'picturefill/dist/plugins/mutation/pf.mutation';
import Loader from 'components/Loader/Loader';
import './Picture.scss';

/**
 * This is the Picture component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */

const Picture = forwardRef(
  (
    {
      className,
      focalPoint,
      sources,
      img,
      width,
      height,
      onResize,
      onLoad,
      onError,
    },
    ref
  ) => {
    const imageRef = useRef(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    const resizeListener = throttle(() => {
      onResize(imageRef.current);
    });

    useEffect(() => {
      window.addEventListener('resize', resizeListener);
      return function cleanup() {
        window.removeEventListener('resize', resizeListener);
      };
    });

    useEffect(() => {
      picturefill(imageRef.current);
      objectFitImages(imageRef.current);
    }, []); // did mount

    useEffect(() => {
      if (ref && imageRef) {
        ref.current = imageRef.current;
      }
    }, []);

    function getStyle() {
      if (!focalPoint) return {};

      return {
        objectPosition: `${focalPoint.x * 100}% ${focalPoint.y * 100}%`,
      };
    }

    function renderLoader() {
      if (!loading) return null;

      return <Loader />;
    }

    if (!sources.length) {
      return (
        <>
          <img
            className={classnames(className, { loading, error })}
            style={getStyle()}
            ref={imageRef}
            width={width}
            height={height}
            {...img}
            onError={() => {
              setError(true);
              onError();
            }}
            onLoad={e => {
              setError(false);
              setLoading(false);
              resizeListener();
              onLoad(e);
            }}
          />
          {renderLoader()}
        </>
      );
    }

    return (
      <picture className={classnames(className, { loading, error })}>
        {sources.map((props, key) => (
          <source key={key} {...props} />
        ))}
        <img
          ref={imageRef}
          style={getStyle()}
          width={width}
          height={height}
          {...img}
          onError={() => {
            setError(true);
            onError();
          }}
          onLoad={e => {
            setError(false);
            setLoading(false);
            resizeListener();
            onLoad(e);
          }}
        />
        {renderLoader()}
      </picture>
    );
  }
);

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Picture.propTypes = {
  className: PropTypes.string,
  focalPoint: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  sources: PropTypes.arrayOf(PropTypes.object),
  img: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  onLoad: PropTypes.func,
  onError: PropTypes.func,
  onResize: PropTypes.func,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Picture.defaultProps = {
  className: null,
  sources: [],
  img: {},
  width: null,
  height: null,
  onError: () => {},
  onLoad: () => {},
  onResize: () => {},
};

export default Picture;
