/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Cart from 'components/Icons/Cart/Cart';
import Client from './Client/Client';
import Time from './Time/Time';
import './Basket.scss';

/**
 * This is the Basket component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
const Basket = ({
  loginLink,
  registerLink,
  profileLink,
  client,
  items,
  expires,
  url,
  labels,
  onExpired,
  onRefresh,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.body.classList.add('with-basket');

    return function cleanup() {
      document.body.classList.remove('with-basket');
    };
  }, []);

  const grouped = {};

  const tickets = items.filter(item => item.type === 'Ticket');

  tickets.forEach(({ name, price, details }) => {
    const id = `ticket-${name}-${details}`;
    if (!grouped[id]) {
      grouped[id] = {
        type: 'tickets',
        name,
        number: 0,
        price: 0,
      };
    }
    grouped[id].number += 1;
    grouped[id].price += price;
  });

  const merchandise = items.filter(item => item.type === 'Product');

  merchandise.forEach(({ name, price, details }) => {
    const id = `merch-${name}-${details}`;
    if (!grouped[id]) {
      grouped[id] = {
        type: 'merchandise',
        name,
        number: 0,
        price: 0,
      };
    }
    grouped[id].number += 1;
    grouped[id].price += price;
  });

  const sumTotal = Object.keys(grouped).reduce((sum, key) => {
    return sum + grouped[key].price;
  }, 0);

  let timeout = null;

  function showItems() {
    clearTimeout(timeout);
    setOpen(true);
  }

  function hideItems() {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setOpen(false);
    }, 200);
  }

  function renderNumberLabel(type, number) {
    if (type === 'tickets') {
      if (number < 2) {
        return labels.tickets.single.replace('{number}', number);
      }
      return labels.tickets.multiple.replace('{number}', number);
    }

    if (number < 2) {
      return labels.merchandise.single.replace('{number}', number);
    }
    return labels.merchandise.multiple.replace('{number}', number);
  }

  function renderClientLinks() {
    if (client && client.id) {
      return <Client {...client} profileLink={profileLink} />;
    }

    return [
      <a key="signin" href={loginLink}>
        {labels.signin}
      </a>,
      <a key="register" href={registerLink}>
        {labels.register}
      </a>,
    ];
  }

  return (
    <div
      className={classnames('basket', { 'show-items': open })}
      appear="true"
      root-margin="40px 0px 0px 0px"
      timeout="{2000}"
    >
      <div className="block-max">
        <div className="top">
          <div className="outer-container">
            {renderClientLinks()}
            <Cart />
            <button
              className="shopping-basket"
              onMouseEnter={showItems}
              onMouseLeave={hideItems}
              onClick={() => {
                setOpen(visible => !visible);
              }}
            >
              <span className="count">
                {tickets.length + merchandise.length}
              </span>
            </button>
            <Time
              expires={expires}
              onRefresh={onRefresh}
              onExpired={onExpired}
            />
          </div>
        </div>
        <div className="outer-container details-container">
          <div
            className="details"
            onMouseEnter={showItems}
            onMouseLeave={hideItems}
          >
            <div>
              <div>
                <ul>
                  {Object.keys(grouped).map(key => (
                    <li key={key}>
                      <strong>{grouped[key].name}</strong>
                      <div>
                        <span>
                          {renderNumberLabel(
                            grouped[key].type,
                            grouped[key].number
                          )}
                        </span>
                        <span>
                          {grouped[key].price.toLocaleString('nb', {
                            currency: 'NOK',
                            style: 'currency',
                          })}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="total">
                  <span>{labels.total}</span>
                  <strong className="sum">
                    {sumTotal.toLocaleString('nb', {
                      currency: 'NOK',
                      style: 'currency',
                    })}
                  </strong>
                </div>

                <a
                  className="ticket-button"
                  href={`${url}?returnUrl=${encodeURIComponent(
                    window.location.href
                  )}`}
                >
                  <span>{labels.toBasket}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Basket.propTypes = {
  url: PropTypes.string.isRequired,
  loginLink: PropTypes.string.isRequired,
  registerLink: PropTypes.string.isRequired,
  profileLink: PropTypes.string.isRequired,
  client: PropTypes.object,
  expires: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    })
  ).isRequired,
  labels: PropTypes.shape({
    tickets: PropTypes.shape({
      single: PropTypes.string.isRequired,
      multiple: PropTypes.string.isRequired,
    }).isRequired,
    merchandise: PropTypes.shape({
      single: PropTypes.string.isRequired,
      multiple: PropTypes.string.isRequired,
    }).isRequired,
    signin: PropTypes.string.isRequired,
    register: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    toBasket: PropTypes.string.isRequired,
  }).isRequired,
  onExpired: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Basket.defaultProps = {
  client: null,
  items: [],
  sumTotal: 0,
  expires: 0,
  labels: {
    tickets: {
      single: '{number} ticket',
      multiple: '{number} tickets',
    },
    merchandise: {
      single: '{number} merchandise',
      multiple: '{number} merchandise',
    },
    signin: 'Sign In',
    register: 'Register',
    total: 'Total',
    toBasket: 'To basket',
  },
  onExpired: () => {},
  onRefresh: () => {},
};

export default Basket;
