/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useKeys from 'effects/keys';
import Magnifier from 'components/Icons/Magnifier/Magnifier';

import './Search.scss';

/**
 * This is the Search component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
const Search = ({ action, labels, onClose }) => {
  const [visible, setVisible] = useState(false);
  const [focus, setFocus] = useState(false);
  const [text, setText] = useState(false);

  const hide = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(true);
    document.body.classList.add('no-scroll-menu');

    return function cleanup() {
      document.body.classList.remove('no-scroll-menu');
    };
  }, []); // only on mount

  useKeys('search', visible, {
    esc: () => {
      setVisible(false);
    },
  });

  function onTransitionEnd() {
    if (!visible) {
      onClose();
    }
  }

  const onContainerClick = ({ target }) => {
    if (target.nodeName === 'DIV') {
      hide();
    }
  };

  // eslint-disable-next-line
  const renderPlaceholder = () => {
    if (!focus || text) return null;

    return <div className="placeholder">{labels.lookingFor}</div>;
  };

  return (
    <div
      id="search-wrapper"
      className={classnames('search-wrapper', { visible })}
      onTransitionEnd={onTransitionEnd}
      onClick={onContainerClick}
    >
      <form
        role="search"
        method="GET"
        id="searchform"
        className="searchform"
        action={action}
        onClick={e => e.stopPropagation()}
      >
        <label className="screen-reader-text" htmlFor="headerSearch">
          {labels.searchFor}
        </label>
        <div className="search-fields-wrapper">
          <input
            type="text"
            name="s"
            id="headerSearch"
            autoComplete="off"
            autoFocus
            placeholder={labels.lookingFor}
            onChange={({ target: { value } }) => {
              setText(value.length > 0);
            }}
            onFocus={() => {
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
            }}
          />

          <button type="submit">
            <Magnifier />
          </button>
        </div>
      </form>
    </div>
  );
};

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Search.propTypes = {
  action: PropTypes.string.isRequired,
  labels: PropTypes.object,
  onClose: PropTypes.func,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Search.defaultProps = {
  labels: {
    searchFor: 'Leit etter:',
    lookingFor: 'Kva ser du etter?',
    close: 'Lukk',
  },
  onClose: () => {},
};

export default Search;
