import nn from 'date-fns/locale/nn/_lib/localize';
import _index from 'date-fns/locale/_lib/buildLocalizeFn';

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var _index2 = _interopRequireDefault(_index);

const dayValues = {
  narrow: ['S', 'M', 'T', 'O', 'T', 'F', 'L'],
  short: ['sø', 'må', 'ty', 'on', 'to', 'fr', 'la'],
  abbreviated: ['søn', 'mån', 'tys', 'ons', 'tor', 'fre', 'lau'],
  wide: [
    'søndag',
    'måndag',
    'tysdag',
    'onsdag',
    'torsdag',
    'fredag',
    'laurdag',
  ],
};

export default {
  ...nn,
  day: (0, _index2.default)({
    values: dayValues,
    defaultWidth: 'wide',
  }),
};
