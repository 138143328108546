import scrollTo from 'utils/scrollTo';

class Freeform {
  constructor(node, options = {}) {
    this.props = {
      ...options,
    };

    this.node = node;

    this.messageNode = this.node.querySelector(
      '.freeform-form-has-errors, .ff-form-success'
    );

    if (this.messageNode) {
      this.init();
    }
  }

  init = () => {
    scrollTo(this.messageNode, { alignTop: true, offset: 140 });
  };
}

export default (node, props) => new Freeform(node, props);
