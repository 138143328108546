/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useKeys from 'effects/keys';
import './Submenu.scss';

const letters = 'abcdefghijklmnopqrstuvwxyzæøå'.split('');

/**
 * This is the Submenu component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Submenu({ id, items, open, onClose }) {
  const content = useRef(null);
  const [current, setCurrent] = useState(0);
  const [height, setHeight] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [opened, setOpened] = useState(0);
  const letterKeys = {};

  function onLetter({ key }) {
    let index = items.findIndex(({ title }) => {
      return title[0].toLowerCase() === key;
    }, current + 1);

    if (index === -1) {
      index = items.findIndex(({ title }) => {
        return title[0].toLowerCase() === key;
      });
    }

    if (index !== -1) {
      setCurrent(index);
    }
  }

  letters.forEach(letter => {
    letterKeys[letter] = onLetter;
  });

  useKeys('submenu', open, {
    esc: onClose,
    up: e => {
      e.preventDefault();
      setCurrent(prev => {
        const next = prev - 1;
        if (next < 0) {
          return items.length - 1;
        }

        return next;
      });
    },
    down: e => {
      e.preventDefault();
      setCurrent(prev => {
        const next = prev + 1;
        if (next >= items.length) {
          return 0;
        }

        return next;
      });
    },
    home: e => {
      e.preventDefault();
      setCurrent(0);
    },
    end: e => {
      e.preventDefault();
      setCurrent(items.length - 1);
    },
    ...letterKeys,
  });

  useEffect(() => {
    if (open && opened) {
      const item = content.current.querySelector(
        `li:nth-child(${current + 1}) > a`
      );
      if (item) {
        item.focus();
      }
    }
  }, [opened, open, current]);

  useEffect(() => {
    if (!open) {
      setCurrent(0);
    }
  }, [open]);

  useEffect(() => {
    function onResize() {
      setMaxHeight(content.current.offsetHeight);
    }

    window.addEventListener('resize', onResize);
    const timeout = setTimeout(onResize, 120);
    return function cleanup() {
      clearTimeout(timeout);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (open) {
      setHeight(maxHeight);
    } else {
      setHeight(0);
    }
  }, [open, maxHeight]);

  return (
    <div
      id={id}
      className="submenu"
      style={{ height }}
      onTransitionEnd={() => {
        setOpened(open);
      }}
    >
      <ul ref={content} role="none">
        {items.map(({ key, title, ...childProps }) => (
          <li key={key} role="none">
            <a
              {...childProps}
              tabIndex={open ? null : -1}
              data-event="Undermeny"
              data-label={title}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Submenu.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  items: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Submenu.defaultProps = {
  id: 'sub',
  open: false,
  items: [],
  onOpen: () => {},
  onClose: () => {},
};

export default Submenu;
