/*
 * This file is part of the Lundhs Realstone Architects 2018 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import range from 'lodash/range';
import './Loader.scss';
/**
 * This is the Loader component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
const Loader = () => (
  <div className="loader">
    {range(0, 12).map(i => (
      <div key={i} />
    ))}
  </div>
);

export default Loader;
