/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the Program component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Program({ title }) {
  return (
    <span className="svg svg-program">
      <span>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 15.9 19.5"
          enableBackground="new 0 0 15.9 19.5"
          xmlSpace="preserve"
          aria-hidden="true"
        >
          <title>{title}</title>
          <rect
            className="a"
            x="1"
            y="1"
            fill="#FFFFFF"
            stroke="#000000"
            strokeWidth="2"
            width="13.9"
            height="17.5"
          />
          <line
            className="b"
            fill="none"
            stroke="#000000"
            strokeWidth="2"
            x1="4.1"
            y1="15"
            x2="12.1"
            y2="15"
          />
          <rect
            className="c"
            x="5.1"
            y="5"
            fill="#FFFFFF"
            stroke="#000000"
            strokeWidth="2"
            width="6"
            height="6"
          />
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Program.propTypes = {
  title: PropTypes.string.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Program.defaultProps = {
  title: 'Program',
};

export default Program;
