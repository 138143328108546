import registerDecoration from 'utils/registerDecoration';

import linedText from 'decorators/lined-text';
import dropcap from 'decorators/dropcap';
import inview from 'decorators/inview';
import stagger from 'decorators/stagger';
import appearText from 'decorators/appearText';
import staggerText from 'decorators/staggerText';
import tracking from 'decorators/tracking';
import freeform from 'decorators/freeform';
import homeLink from 'decorators/homeLink';
import toTop from 'decorators/toTop';
import gaEcommerceTracking from 'decorators/ga-ecommerce-tracking';

export default () => {
  registerDecoration('.global-header .logo', homeLink);
  registerDecoration('.lined-text', linedText);
  registerDecoration(
    '.article-entry.with-dropcap .block-text-text.index-1 .body p:first-child, .article-entry.with-dropcap .block-image-text.index-1 .body p:first-child',
    dropcap
  );

  registerDecoration('[appear]', inview, {
    name: 'appear',
    timeout: 10000,
    threshold: [0, 0.7, 1],
    rootMargin: '0px 0px 50px 0px',
    props: ['transform'],
    once: true,
  });

  registerDecoration('[stagger]', stagger, {
    threshold: [0.3, 1],
  });
  registerDecoration('[appear-text]', appearText, {
    timeout: 10000,
    threshold: [0, 0.7, 1],
    rootMargin: '0px 0px 50px 0px',
    props: ['opacity'],
    once: true,
  });

  registerDecoration('[stagger-text]', staggerText, {
    duration: 0.5,
    threshold: [0, 0.7, 1],
    rootMargin: '50px 0px 0px 0px',
  });

  registerDecoration(
    '.global-navigation a, .global-navigation button, .featured-navigation a, .ticket-button, .global-footer__social__item',
    tracking
  );

  registerDecoration('.block-form', freeform);
  registerDecoration('.to-top', toTop);
  registerDecoration('[ga-ecommerce-tracking]', gaEcommerceTracking);
};
