/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import Picture from 'components/Picture/Picture';
import Video from './Video/Video';
import './PlayHeader.scss';

function isLandscape() {
  const [ratio, setRatio] = useState(window.innerWidth / window.innerHeight);

  const resizeListener = debounce(() => {
    setRatio(window.innerWidth / window.innerHeight);
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return function cleanup() {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return ratio > 1;
}

function getVideoControlPosition(ref) {
  const [position, setPosition] = useState(null);

  const onResize = debounce(() => {
    if (ref.current) {
      const computedStyle = getComputedStyle(ref.current);
      const backgroundColor = computedStyle.getPropertyValue(
        'background-color'
      );

      if (backgroundColor === 'rgb(30, 30, 30)') {
        setPosition({ bottom: ref.current.offsetHeight });
      } else {
        setPosition(null);
      }
    }
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return function cleanup() {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return position;
}

function getVideo(video) {
  if (!video) return null;
  const landscape = isLandscape();

  if (landscape && video.landscape) {
    return video.landscape;
  }

  if (!landscape && video.portrait) {
    return video.portrait;
  }

  return null;
}

/**
 * This is the PlayHeader component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
const PlayHeader = ({
  title,
  byline,
  poster,
  alignment,
  videos,
  ticket,
  more,
  labels,
}) => {
  const video = getVideo(videos);
  const infoRef = useRef(null);
  const videoControlPosition = getVideoControlPosition(infoRef);

  function renderTitle() {
    if (more) {
      const { text, ...moreProps } = more; //eslint-disable-line no-unused-vars
      return (
        <h1>
          <a {...moreProps}>{title}</a>
        </h1>
      );
    }

    return <h1>{title}</h1>;
  }

  function renderVideo() {
    if (!video) return null;

    return (
      <Video
        {...video}
        controlPosition={videoControlPosition}
        labels={labels}
      />
    );
  }

  function renderTicket() {
    if (!ticket) return null;

    const { text, ...ticketProps } = ticket;

    return (
      <a className="btn buy icon-icon-ticket" {...ticketProps}>
        <span>{text}</span>
      </a>
    );
  }

  function renderMore() {
    if (!more) return null;

    const { text, ...moreProps } = more;

    return (
      <a className="btn readmore" {...moreProps}>
        {text}
      </a>
    );
  }

  return (
    <div className="main-play image-background play-header">
      <Picture {...poster} />
      {renderVideo()}
      <div className={classnames('main-play-info', alignment)}>
        <div className="main-play-info-inner">
          <div ref={infoRef} className="colorbg">
            {renderTitle()}
            {byline ? <p className="by-line">{byline}</p> : null}
            {renderTicket()}
            {renderMore()}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
PlayHeader.propTypes = {
  title: PropTypes.string,
  byline: PropTypes.string,
  alignment: PropTypes.string,
  poster: PropTypes.object,
  videos: PropTypes.shape({
    portrait: PropTypes.object,
    landscape: PropTypes.object,
  }),
  more: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  ticket: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  labels: PropTypes.object,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
PlayHeader.defaultProps = {
  title: null,
  byline: null,
  alignment: 'default',
  poster: {},
  videos: {
    portrait: null,
    landscape: null,
  },
  more: null,
  ticket: null,
};

export default PlayHeader;
