/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Choices from './Choices/Choices';

/**
 * This is the Field component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Field({ id, type, label, value, error, required, ...props }) {
  function renderError() {
    if (!error) return null;

    return (
      <span className="error" aria-live="polite">
        {error}
      </span>
    );
  }

  function renderInput() {
    switch (type) {
      case 'choices':
        return <Choices id={id} value={value} required={required} {...props} />;
      case 'checkbox':
        return (
          <input
            id={id}
            type={type}
            required={required}
            checked={value}
            {...props}
            onChange={e => {
              props.onChange({
                target: {
                  value: e.target.checked,
                },
              });
            }}
          />
        );
      default:
        return (
          <input
            id={id}
            type={type}
            value={value}
            required={required}
            {...props}
          />
        );
    }
  }

  function renderRow() {
    if (type === 'checkbox' || type === 'radio') {
      return (
        <div
          className={`field-input field-type-${type} ${type} field-name-${id}`}
        >
          {renderInput()}
          <label
            className={classnames({ required })}
            htmlFor={id}
            dangerouslySetInnerHTML={{ __html: label }}
          />
          {renderError()}
        </div>
      );
    }

    if (type === 'choices') {
      return (
        <fieldset>
          <legend className={classnames({ required })}>{label}</legend>
          <div className={`field-input field-type-${type} field-name-${id}`}>
            {renderInput()}
            {renderError()}
          </div>
        </fieldset>
      );
    }

    return (
      <>
        <label className={classnames({ required })} htmlFor={id}>
          {label}
        </label>
        <div className={`field-input field-type-${type} field-name-${id}`}>
          {renderInput()}
          {renderError()}
        </div>
      </>
    );
  }

  return (
    <div
      key={id}
      className={classnames('field-row', `field-row-${type} field-name-${id}`, {
        'with-error': !!error,
      })}
    >
      {renderRow()}
    </div>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Field.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
  ]).isRequired,
  required: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Field.defaultProps = {
  type: 'text',
  value: '',
  required: false,
  error: null,
  onChange: () => {},
};

export default Field;
