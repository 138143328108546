/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the Checkbox component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Choices({
  id,
  multiple,
  description,
  options,
  value: selected,
  onChange,
  ...props
}) {
  function renderDescription() {
    if (!description) return null;

    return (
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    );
  }

  function renderInput(value) {
    if (multiple) {
      const checked = (selected || []).indexOf(value) > -1;

      return (
        <input
          id={`${id}-${value}`}
          type="checkbox"
          value={value}
          checked={checked}
          onChange={() => {
            let newValue = selected;

            if (checked) {
              newValue = selected.filter(current => current !== value);
            } else {
              newValue = [...selected, value];
            }
            onChange({ target: { value: newValue } });
          }}
          {...props}
        />
      );
    }

    return (
      <input
        id={`${id}-${value}`}
        type="radio"
        value={value}
        checked={selected == value}
        onChange={() => {
          onChange({ target: { value } });
        }}
        {...props}
      />
    );
  }

  return (
    <>
      {renderDescription()}
      <div className="options">
        {options.map(({ label, value }) => {
          return (
            <div key={`${id}-${value}`} className="checkbox">
              {renderInput(value)}
              <label htmlFor={`${id}-${value}`} className="checkbox">
                <span>{label}</span>
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Choices.propTypes = {
  id: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  description: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
      ]),
    })
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange: PropTypes.func.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Choices.defaultProps = {
  id: 'choices',
  multiple: false,
  options: [],
  onChange: () => {},
};

export default Choices;
