/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * This is the Epicenter component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Epicenter({ title, animate }) {
  return (
    <span className={classnames('svg svg-square svg-epicenter', { animate })}>
      <span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <title>{title}</title>
          <path
            className="inner"
            d="M15.7118 7C17.1054 8.16527 18 9.97183 18 12C18 14.0282 17.1054 15.8347 15.7118 17M8.28823 7C6.89465 8.16527 6 9.97183 6 12C6 14.0282 6.89465 15.8347 8.28823 17"
            stroke="#791526"
          />
          <path
            className="outer"
            d="M18.1863 4C20.5089 5.86442 22 8.75492 22 12C22 15.2451 20.5089 18.1356 18.1863 20M5.81372 4C3.49108 5.86442 2 8.75492 2 12C2 15.2451 3.49108 18.1356 5.81372 20"
            stroke="#791526"
          />
          <circle cx="12" cy="12" r="3" fill="#791526" />
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Epicenter.propTypes = {
  title: PropTypes.string.isRequired,
  animate: PropTypes.bool,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Epicenter.defaultProps = {
  title: 'Epicenter',
  animate: false,
};

export default Epicenter;
