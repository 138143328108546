/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import classnames from 'classnames';
import screenfull from 'screenfull';
import Arrow from 'components/Icons/Arrow/Arrow';
import Picture from 'components/Picture/Picture';
import useInview from 'effects/inview';
import useKeys from 'effects/keys';
import useGuid from 'effects/useGuid';
import FullscreenIcon from 'components/Icons/Fullscreen/Fullscreen';
import ExitIcon from 'components/Icons/Exit/Exit';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import './Gallery.scss';

/**
 * This is the Gallery component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
const Gallery = ({ heading, slides, labels }) => {
  const guid = useGuid('gallery');
  const [ref, inview] = useInview({ threshold: 0.6 });
  const [fullscreen, setFullscreen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [style, setStyle] = useState(null);
  const sliderRef = useRef(null);

  useKeys('gallery', inview || fullscreen, {
    left: e => {
      e.preventDefault();
      sliderRef.current.slidePrev(800);
    },
    right: e => {
      e.preventDefault();
      sliderRef.current.slideNext(800);
    },
  });

  useEffect(() => {
    function onFullScreenchange({ target }) {
      if (target === ref.current) {
        setFullscreen(screenfull.isFullscreen);
      }
    }

    if (screenfull.isEnabled) {
      screenfull.on('change', onFullScreenchange);
    }

    return function cleanup() {
      if (screenfull.isEnabled) {
        screenfull.on('off', onFullScreenchange);
      }
    };
  });

  useEffect(() => {
    if (fullscreen && !screenfull.isEnabled) {
      document.body.classList.add('full-screen-gallery-active');
    }

    return () => {
      document.body.classList.remove('full-screen-gallery-active');
    };
  }, [fullscreen]);

  useEffect(() => {
    function onResize() {
      let style = null;
      const height = window.innerWidth * 1.5;

      if (height > window.innerHeight * 0.9) {
        const newHeight = window.innerHeight * 0.9;
        const width = newHeight * 1.5;

        if (width < window.innerWidth) {
          style = {
            width: `${width}px`,
          };
        }
      }
      setStyle(style);
    }

    if (fullscreen) {
      window.addEventListener('resize', onResize);
      onResize();
    }

    return () => {
      window.removeEventListener('resize', onResize);
      setStyle(null);
    };
  }, [fullscreen]);

  if (slides.length === 0) {
    return null;
  }

  function renderFullscreenEnter() {
    if (fullscreen) return null;

    return (
      <button
        className="fullscreen-enter"
        type="button"
        aria-label={labels.fullscreen}
        onClick={() => {
          if (screenfull.isEnabled) {
            screenfull.request(ref.current);
          } else {
            setFullscreen(true);
          }
        }}
      >
        <FullscreenIcon title={labels.fullscreen} aria-hidden />
      </button>
    );
  }

  function renderFullscreenExit() {
    if (!fullscreen) return null;

    return (
      <button
        className="fullscreen-exit"
        type="button"
        onClick={() => {
          if (screenfull.isEnabled) {
            screenfull.exit(ref.current);
          } else {
            setFullscreen(false);
          }
        }}
      >
        <ExitIcon title={labels.exit} />
      </button>
    );
  }

  /* eslint-disable-next-line */
  function renderCaption({ caption }) {
    if (!caption) return null;

    return <span>{caption}</span>;
  }

  /* eslint-disable-next-line */
  function renderCopyright({ copyright }) {
    if (!copyright) return null;

    return <small>{copyright}</small>;
  }

  if (slides.length === 1) {
    return (
      <aside className="gallery ">
        <div>
          <figure>
            <Picture {...slides[0].image} />
            <figcaption>
              {renderCaption(slides[0])}
              {renderCopyright(slides[0])}
            </figcaption>
          </figure>
        </div>
      </aside>
    );
  }

  return (
    <div
      ref={ref}
      role="region"
      aria-roledescription="carousel"
      aria-label={heading}
      className={classnames('gallery', {
        fullscreen,
        'fullscreen-fallback': !screenfull.isEnabled && fullscreen,
      })}
    >
      <div style={style}>
        <div id={guid} aria-live="polite" className="slider-container">
          <Swiper
            loop
            onSwiper={swiper => {
              sliderRef.current = swiper;
            }}
            onSlideChange={e => {
              setCurrent(e.realIndex);
            }}
          >
            {slides.map(({ key, image, ratio }, i) => (
              <SwiperSlide
                key={key}
                role="group"
                aria-roledescription="slide"
                aria-label={`${i + 1} ${labels.of} ${slides.length}`}
                aria-hidden={current === i ? 'false' : 'true'}
                className={classnames('slide', {
                  current: i === current,
                  invert: ratio < 810 / 1215,
                })}
              >
                <div>
                  <Picture {...image} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {renderFullscreenEnter()}
        </div>
        <footer>
          <div className="caption">
            {renderCaption(slides[current])}
            {renderCopyright(slides[current])}
          </div>
          <nav aria-label={labels.paging}>
            <button
              className="prev"
              title={labels.prev}
              aria-label={labels.prev}
              aria-controls={guid}
              onClick={() => {
                sliderRef.current.slidePrev(800);
              }}
            >
              <Arrow title={labels.prev} left aria-hidden="true" />
            </button>
            <span>
              {current + 1} {labels.of} {slides.length}
            </span>
            <button
              className="next"
              title={labels.next}
              aria-label={labels.next}
              aria-controls={guid}
              onClick={() => {
                sliderRef.current.slideNext(800);
              }}
            >
              <Arrow title={labels.next} aria-hidden="true" />
            </button>
          </nav>
        </footer>
      </div>
      {renderFullscreenExit()}
    </div>
  );
};

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Gallery.propTypes = {
  heading: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string,
      caption: PropTypes.string,
      copyright: PropTypes.string,
      image: PropTypes.object,
    })
  ),
  labels: PropTypes.object,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Gallery.defaultProps = {
  heading: '',
  slides: [],
  labels: {
    paging: 'Browse performances',
    prev: 'Prevous',
    next: 'Next',
    download: 'Download ({size})',
    fullscreen: 'Enter fullscreen',
  },
};

export default Gallery;
