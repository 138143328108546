class Tixly {
  #iframe = null;
  #url = '';
  #loading = false;
  #events = [];

  constructor() {
    this.#iframe = document.createElement('iframe');
    this.#iframe.classList.add('visible-hidden');
    this.#iframe.name = 'tixly-itix-user';
    document.body.appendChild(this.#iframe);

    window.addEventListener('message', this.onMessage, false);
    this.#iframe.addEventListener('load', this.onLoad);
  }

  onLoad = () => {
    this.#iframe.contentWindow.postMessage('GetSession', '*');
    this.#loading = false;
  };

  onMessage = event => {
    if (!this.#url) return;
    const url = new URL(this.#url);
    if (event.origin !== url.origin) return;
    const data = event.data;
    this.#events.forEach(event => {
      event(data);
    });
  };

  setUrl = url => {
    this.#url = url;
    this.refresh();
  };

  refresh = () => {
    if (!this.#loading) {
      this.#loading = true;
      this.#iframe.src = this.#url;
    }
  };

  // add event to the list if it doesn't exist
  addEventListener = event => {
    if (this.#events.includes(event)) {
      return;
    }

    this.#events.push(event);
  };

  // remove event from the list if it exists
  removeEventListener = event => {
    if (!this.#events.includes(event)) {
      return;
    }

    this.#events = this.#events.filter(e => e != event);
  };
}

export const tixly = new Tixly();

export default tixly;
