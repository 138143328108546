import FontFaceObserver from 'fontfaceobserver';

const fontObserver = new FontFaceObserver('Cormorant Garamond', {
  weight: 400,
});

class LinedText {
  constructor(node, options = {}) {
    this.props = {
      ...options,
    };
    this.node = node;
    this.prevWith;

    /* prevent double decoration */
    if (this.node.decorator) {
      if (this.node.decorator instanceof LinedText) {
        this.node.decorator.destroy();
      }
    }

    this.node.decorator = this;
    this.timeout = null;

    fontObserver.load().then(this.create);
  }

  create = () => {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.node.dispatchEvent(new Event('onLinedTextCreate'));
  };

  destroy = () => {
    this.reset();
    window.removeEventListener('resize', this.onResize);
    this.node.decorator = null;
  };

  reset = () => {
    clearTimeout(this.timeout);
    this.node.style.width = '';
    this.node.style.display = '';
  };

  onResize = () => {
    if (this.prevWith !== window.innerWidth) {
      this.reset();
      this.prevWith = window.innerWidth;
      this.node.style.display = 'inline';
      this.timeout = setTimeout(() => {
        this.node.style.width = `${this.node.offsetWidth + 1}px`;
        this.node.style.display = '';
        this.node.dispatchEvent(new Event('onLinedTextResize'));
      }, 60);
    }
  };
}

export default (node, props) => new LinedText(node, props);
