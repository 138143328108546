import { useRef, useState, useEffect } from 'react';
import globalHook from 'use-global-hook';
import useCsrf from 'effects/csrf';
import useDeepCompare from 'effects/useDeepCompare';
import api from 'utils/api';
import tixly from 'tixly/api';

let busy = false;
let timeout = null;

const initialClient = window.initialClient ?? {
  state: null,
  clientActions: null,
};

const initialProfileState = {
  url: null,
};

const emptyClientState = {
  id: null,
  name: null,
  email: null,
  hash: null,
  programs: [],
  tixly: false,
  server: false,
  saved: false,
};

const initialClientState = {
  ...emptyClientState,
  ...initialClient.state,
};

const initialOrderState = {
  items: [],
  expires: 0,
  url: null,
};

const url = {
  current: '/tixly/client/current',
  login: '/tixly/client/login',
  ...initialClient.clientActions,
};

const profileActions = {
  update: (store, payload) => {
    store.setState({
      ...store.state,
      ...payload,
    });
  },
};

const clientActions = {
  fetchFromServer: store => {
    if (!busy) {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        busy = true;
        api(url.current)
          .then(payload => {
            store.setState({
              ...store.state,
              ...payload,
            });
            busy = false;
          })
          .catch(() => {
            busy = false;
          });
      }, 240);
    }
  },

  saveToServer: (store, csrf) => {
    return new Promise((resolve, reject) => {
      if (!busy) {
        busy = true;
        api(
          url.login,
          'POST',
          {
            [csrf.name]: csrf.value,
            client: store.state,
          },
          csrf
        )
          .then(payload => {
            store.setState({
              ...store.state,
              ...payload,
            });
            busy = false;
            resolve(payload);
          })
          .catch(e => {
            busy = false;
            reject(e);
          });
      }
    });
  },

  update: (store, payload) => {
    store.setState({
      ...store.state,
      ...payload,
    });
  },
};

const orderActions = {
  update: (store, payload) => {
    store.setState({
      ...initialOrderState,
      ...payload,
    });
  },
};

const useGlobalProfile = globalHook(initialProfileState, profileActions);
const useGlobalClient = globalHook(initialClientState, clientActions);
const useGlobalOrder = globalHook(initialOrderState, orderActions);

export function useTixlyClient() {
  const [client] = useGlobalClient();

  // eslint-disable-next-line no-unused-vars
  const [init, setInit] = useState(false);

  useEffect(() => {
    setInit(true);
  }, [useDeepCompare(client)]);

  return client;
}

export function useTixlyOrder() {
  const [order] = useGlobalOrder();

  // eslint-disable-next-line no-unused-vars
  const [init, setInit] = useState(false);

  useEffect(() => {
    setInit(true);
  }, [useDeepCompare(order)]);

  return order;
}

export default itixUrl => {
  const ref = useRef();
  // eslint-disable-next-line no-unused-vars
  const [init, setInit] = useState(false);
  const [csrf, csrfclientActions] = useCsrf();
  const [profile, profileActions] = useGlobalProfile();
  const [client, clientActions] = useGlobalClient();
  const [order, orderActions] = useGlobalOrder();
  const clientId = client.id ?? null;

  useEffect(() => {
    const onChange = data => {
      if (data.profile) {
        profileActions.update({
          url: data.profile,
        });
      }

      if (data.user) {
        clientActions.update({
          ...data.user,
          saved: data.user.id === clientId,
          tixly: true,
        });
      } else {
        clientActions.update({
          ...emptyClientState,
          saved: clientId === null,
          tixly: true,
        });
      }

      orderActions.update(data.order);

      setInit(true);
    };

    tixly.setUrl(itixUrl);
    tixly.addEventListener(onChange);

    return function cleanup() {
      tixly.removeEventListener(onChange);
    };
  }, [itixUrl, clientId]);

  useEffect(() => {
    if (client.tixly && !client.saved) {
      clientActions
        .saveToServer(csrf)
        .then(() => {
          csrfclientActions.refresh();
        })
        .catch(() => {
          csrfclientActions.refresh();
        });
    }
  }, [useDeepCompare(client)]);

  return {
    ref,
    profile: profile.url ?? null,
    client,
    order,
    refresh: tixly.refresh,
  };
};
