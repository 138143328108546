if (typeof window !== 'undefined' && !window.dataLayer) {
  window.dataLayer = [];
}

let views = [];

function cleanData(data = {}) {
  const cleaned = {};

  Object.keys(data).forEach(key => {
    if (data[key]) {
      if (key === 'price') {
        cleaned[key] = parseFloat(data[key]);
      } else {
        cleaned[key] = data[key];
      }
    }
  });

  return cleaned;
}

export class GaEcommerceTracking {
  constructor(node, options = {}) {
    this.props = {
      name: 'GaEcommerceTracking',
      type: 'view',
      rootMargin: '0px 0px 0px 0px',
      threshold: 0.5,
      once: false,
      limit: 10,
      ...options,
      data: cleanData(options.data),
    };
    this.node = node;

    /* prevent double decoration */
    if (this.node.decorator) {
      if (this.node.decorator instanceof GaEcommerceTracking) {
        this.node.decorator.destroy();
      }
    }

    this.node.decorator = this;
    this.timeout = null;
    this.interval = null;

    if (this.props.type === 'view') {
      this.node.addEventListener('ready', this.initializeView);

      this.timeout = setTimeout(this.initializeView, 1000);
    } else {
      this.initializeSelect();
    }
  }

  initializeView = () => {
    this.destroy();
    this.timeout = setTimeout(() => {
      this.button = this.node.querySelector(this.props.button);

      if (this.button) {
        this.button.addEventListener('click', this.trackClick, { once: true });
      }

      this.registerObserver();

      this.interval = setInterval(this.processViewItems, 5000);
    }, 1000);
  };

  initializeSelect = () => {
    this.node.addEventListener('click', this.trackClick, { once: true });
  };

  destroy = () => {
    this.node.removeEventListener('ready', this.initialize);
    this.node.removeEventListener('click', this.trackClick);

    clearTimeout(this.timeout);
    clearInterval(this.interval);

    if (this.button) {
      this.button.removeEventListener('click', this.trackClick);
    }

    if (this.observer) {
      this.observer.unobserve(this.node);
    }

    this.observer = null;
    this.node.decorator = null;
  };

  registerObserver = () => {
    const { rootMargin, threshold } = this.props;
    this.observer = new IntersectionObserver(this.onIntersection, {
      rootMargin,
      threshold,
    });
    this.observer.observe(this.node);
  };

  onIntersection = entries => {
    entries.forEach(({ intersectionRatio }) => {
      if (intersectionRatio > 0) {
        if (this.props.type === 'view') {
          this.trackView();
          if (this.props.once) {
            this.observer.unobserve(this.node);
          }
        }
      }
    });
  };

  processViewItems = () => {
    if (typeof window !== 'undefined' && window.dataLayer && views.length) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
          items: views.map((item, index) => ({ ...item, index: index + 1 })),
        },
      });
      views = [];
    }
  };

  trackView = () => {
    const { data, limit } = this.props;
    const exists = views.find(
      item => JSON.stringify(item) == JSON.stringify(data)
    );

    if (!exists) {
      views.push(data);
      if (views.length >= limit) {
        this.processViewItems();
      }
    }
  };

  trackClick = e => {
    e.preventDefault();
    const { data } = this.props;

    this.processViewItems();
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'select_item',
      ecommerce: {
        currency: data.currency || 'NOK',
        value: data.price,
        item_list_id: data.item_list_id,
        item_list_name: data.item_list_name,
        items: [data],
      },
    });
    this.node.removeEventListener('click', this.trackClick);

    if (this.button) {
      this.button.removeEventListener('click', this.trackClick);
    }

    e.target.click();
  };
}

export default (node, props) => new GaEcommerceTracking(node, props);
