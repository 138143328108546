/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';
import './Client.scss';

/**
 * This is the Client component.
 *
 * @author Thomas Sømoen <¨>
 *
 * @return {JSX}
 */
const Client = ({ profileLink, name }) => {
  if (!name) return null;
  return (
    <div className="client">
      <a href={profileLink}>{name}</a>
    </div>
  );
};

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Client.propTypes = {
  profileLink: PropTypes.string.isRequired,
  name: PropTypes.string,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Client.defaultProps = {};

export default Client;
