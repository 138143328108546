/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './NewsletterPanel.scss';
import useKeys from 'effects/keys';
import useGuid from 'effects/useGuid';
import CloseIcon from 'components/Icons/Exit/Exit';
import Newsletter from 'components/Newsletter/Newsletter';
import { createPortal } from 'react-dom';

const panelNode = document.getElementById('newsletter-panel-container');

function hasCommonID(array1, array2) {
  return array1.some(item => array2.includes(item));
}

/**
 * This is the Newsletter Panel component.
 *
 * @author Thomas Sømoen <thomas.somoen@try.no>
 *
 * @return {JSX}
 */
const NewsletterPanel = forwardRef(function NewsletterPanel(
  { color, requiresBirthday, toggle, newsletter, receipt, labels },
  ref
) {
  const container = useRef(null);
  const buttonRef = useRef(null);
  const formRef = useRef(null);
  const guid = useGuid();
  const [open, setOpen] = useState(false);
  const [birthday, setBirthday] = useState(false);
  const [completed, setCompleted] = useState(false);
  const newslists = newsletter.form.fields.find(field => field.id === 'list');

  useEffect(() => {
    if (newslists && newslists.value.length && requiresBirthday.length) {
      if (hasCommonID(newslists.value, requiresBirthday)) {
        setBirthday(true);
      }
    }
  }, [newslists, requiresBirthday]);

  useEffect(() => {
    if (open) {
      document.body.classList.add('newsletter-panel-open');
    }

    return function cleanup() {
      document.body.classList.remove('newsletter-panel-open');
    };
  }, [open]);

  useEffect(() => {
    if (open && formRef.current) {
      formRef.current.focus();
    }
  }, [open]);

  useImperativeHandle(
    ref,
    () => {
      return {
        open: () => setOpen(true),
        close: () => setOpen(false),
      };
    },
    []
  );

  useKeys('featured', open, {
    esc: () => {
      setOpen(false);
    },
  });

  const onContainerClick = ({ target }) => {
    if (target === container.current) {
      setOpen(false);
    }
  };

  function renderPanelContent() {
    if (completed) {
      return (
        <div className="receipt">
          <h2 className="h3 heading">{receipt.heading}</h2>
          <div
            className="body"
            dangerouslySetInnerHTML={{ __html: receipt.text }}
          />
          <button className="ticket-button" onClick={() => setCompleted(false)}>
            <span>{labels.close}</span>
          </button>
        </div>
      );
    }

    const { heading, text, form, ...newsletterProps } = newsletter;

    const fields = form.fields.filter(field => {
      if (birthday) return true;
      return field.id !== 'birthday';
    });

    return (
      <>
        <h2 className="h3 heading">{heading}</h2>
        <div className="body" dangerouslySetInnerHTML={{ __html: text }} />
        <Newsletter
          ref={formRef}
          inactive={!open}
          {...newsletterProps}
          form={{
            ...form,
            fields,
          }}
          onChange={e => {
            if (e.id === 'list') {
              setBirthday(hasCommonID(e.value, requiresBirthday));
            }
          }}
          onSuccess={() => {
            setCompleted(true);
          }}
        />
      </>
    );
  }

  function renderPanel() {
    const panel = (
      <div
        ref={container}
        id={`newsletter-panel-${guid}`}
        className={classNames('newsletter-panel', `theme-${color}`)}
        aria-hidden={!open}
        onClick={onContainerClick}
        role="dialog"
        aria-labelledby={`newsletter-panel-toggle-${guid}`}
        onTransitionEnd={e => {
          if (
            !open &&
            e.propertyName === 'transform' &&
            e.currentTarget === container.current &&
            buttonRef.current
          ) {
            buttonRef.current.focus();
          }
        }}
      >
        <div>
          <button
            className="exit"
            tabIndex={open ? null : -1}
            onClick={() => setOpen(false)}
            aria-label={labels.close}
          >
            <span>
              <CloseIcon title={labels.close} />
              <span className="text">{labels.close}</span>
            </span>
          </button>
          {renderPanelContent()}
        </div>
      </div>
    );

    if (panelNode) {
      return createPortal(panel, panelNode);
    }

    return panel;
  }

  return (
    <>
      <div
        className={classNames(
          'newsletter-panel-toggle-button',
          `theme-${color}`,
          toggle.classNames
        )}
      >
        <button
          ref={buttonRef}
          id={`newsletter-panel-toggle-${guid}`}
          data-testid={`newsletter-panel-toggle-${guid}`}
          aria-expanded={open}
          aria-controls={`newsletter-panel-${guid}`}
          onClick={() => setOpen(open => !open)}
        >
          <small>{toggle.heading}</small>
          <span>{toggle.text}</span>
        </button>
      </div>
      {renderPanel()}
    </>
  );
});

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
NewsletterPanel.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  requiresBirthday: PropTypes.array.isRequired,
  toggle: PropTypes.shape({
    classNames: PropTypes.string,
    heading: PropTypes.string,
    text: PropTypes.string,
  }),
  newsletter: PropTypes.shape({
    action: PropTypes.string.isRequired,
    heading: PropTypes.string,
    text: PropTypes.string,
    form: PropTypes.object.isRequired,
    receipt: PropTypes.string,
  }).isRequired,
  receipt: PropTypes.shape({
    heading: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  labels: PropTypes.shape({
    close: PropTypes.string.isRequired,
  }).isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
NewsletterPanel.defaultProps = {
  id: 'newsletter-panel',
  color: 'black',
  requiresBirthday: [],
  toggle: {
    heading: 'Sign up for our',
    text: 'Newsletter',
  },
  newsletter: {
    form: {},
    receipt: '<p>Thank you for your interest.</p>',
  },
  receipt: {
    heading: 'Thank you for your interest.',
    text:
      '<p>We have received your submission and will get back to you shortly.</p>',
  },
  labels: {
    close: 'Close',
  },
};

export default NewsletterPanel;
