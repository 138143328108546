import React from 'react';
import loadable from '@loadable/component';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import mobileButton from 'utils/mobileButton';
import registerReact from 'utils/registerReact';
import 'picturefill/dist/plugins/mutation/pf.mutation';

import Loader from 'components/Loader/Loader';
import Menu from 'components/Menu/Menu';
import Gallery from 'components/Gallery/Gallery';
import CookieConsent from 'components/CookieConsent/CookieConsent';
import PlayHeader from 'components/PlayHeader/PlayHeader';
import NewsletterPanel from 'components/NewsletterPanel/NewsletterPanel';

const TicketList = loadable(
  () => import('./components/TicketList/TicketList'),
  {
    fallback: (
      <div className="loadable-loader ticket-list-loader">
        <Loader />
      </div>
    ),
  }
);

const EventTicker = loadable(
  () => import('./components/EventTicker/EventTicker'),
  {
    fallback: (
      <div className="loadable-loader event-ticker-loader">
        <Loader />
      </div>
    ),
  }
);

const ShowSubMenu = loadable(
  () => import('./components/ShowSubMenu/ShowSubMenu'),
  {
    fallback: (
      <div className="loadable-loader show-sub-menu-loader">
        <Loader />
      </div>
    ),
  }
);

const Actors = loadable(() => import('./components/Actors/Actors'), {
  fallback: (
    <div className="loadable-loader actors-loader">
      <Loader />
    </div>
  ),
});

const EventCalendar = loadable(
  () => import('./components/EventCalendar/EventCalendar'),
  {
    fallback: (
      <div className="loadable-loader event-calendar-loader">
        <Loader />
      </div>
    ),
  }
);

const Share = loadable(() => import('./components/Share/Share'), {
  fallback: (
    <div className="loadable-loader share-loader">
      <Loader />
    </div>
  ),
});

const Vimeo = loadable(() => import('./components/Video/Vimeo/Vimeo'), {
  fallback: (
    <div className="loadable-loader video vimeo">
      <Loader />
    </div>
  ),
});

const Youtube = loadable(() => import('./components/Video/Youtube/Youtube'), {
  fallback: (
    <div className="loadable-loader video youtube">
      <Loader />
    </div>
  ),
});

const Video = loadable(() => import('./components/Video/Video'), {
  fallback: (
    <div className="loadable-loader video">
      <Loader />
    </div>
  ),
});

const VideoSimple = loadable(() => import('./components/Video/Simple/Simple'), {
  fallback: (
    <div className="loadable-loader video">
      <Loader />
    </div>
  ),
});

const VideoBackground = loadable(
  () => import('./components/VideoBackground/VideoBackground'),
  {
    fallback: (
      <div className="loadable-loader video">
        <Loader />
      </div>
    ),
  }
);

const RelatedContent = loadable(
  () => import('./components/RelatedContent/RelatedContent'),
  {
    fallback: (
      <div className="loadable-loader related-content">
        <Loader />
      </div>
    ),
  }
);

const EnsembleActors = loadable(
  () => import('./components/Ensemble/Actors/Actors'),
  {
    fallback: (
      <div className="loadable-loader ensemble-loader">
        <Loader />
      </div>
    ),
  }
);

const EnsembleBand = loadable(() => import('./components/Ensemble/Band/Band'), {
  fallback: (
    <div className="loadable-loader ensemble-loader">
      <Loader />
    </div>
  ),
});

const EnsembleArtistic = loadable(
  () => import('./components/Ensemble/Artistic/Artistic'),
  {
    fallback: (
      <div className="loadable-loader ensemble-loader">
        <Loader />
      </div>
    ),
  }
);

const EnsembleContributors = loadable(
  () => import('./components/Ensemble/Contributors/Contributors'),
  {
    fallback: (
      <div className="loadable-loader ensemble-loader">
        <Loader />
      </div>
    ),
  }
);

const ArticleList = loadable(
  () => import('./components/ArticleList/ArticleList'),
  {
    fallback: (
      <div className="loadable-loader article-list-loader">
        <Loader />
      </div>
    ),
  }
);

const Alert = loadable(() => import('./components/Alert/Alert'), {
  fallback: (
    <div className="loadable-loader alert-loader">
      <Loader />
    </div>
  ),
});

const Insight = loadable(() => import('./components/Insight/Insight'), {
  fallback: (
    <div className="loadable-loader insight-loader">
      <Loader />
    </div>
  ),
});

const VideoStream = loadable(
  () => import('./components/VideoStream/VideoStream'),
  {
    fallback: (
      <div className="loadable-loader video-stream-loader">
        <Loader />
      </div>
    ),
  }
);

const Popup = loadable(() => import('./components/Popup/Popup'), {
  fallback: (
    <div className="loadable-loader popup-loader">
      <Loader />
    </div>
  ),
});

const ProfileTickets = loadable(
  () => import('./components/ProfileTickets/ProfileTickets'),
  {
    fallback: (
      <div className="loadable-loader profile-tickets-loader">
        <Loader />
      </div>
    ),
  }
);

const ProfileStream = loadable(
  () => import('./components/ProfileStream/ProfileStream'),
  {
    fallback: (
      <div className="loadable-loader profile-stream-loader">
        <Loader />
      </div>
    ),
  }
);

const StreamCountdownLink = loadable(
  () => import('./components/StreamCountdownLink/StreamCountdownLink'),
  {
    fallback: (
      <div className="loadable-loader stream-countdown-link-loader">
        <Loader />
      </div>
    ),
  }
);

const ProgramLink = loadable(
  () => import('./components/ProgramLink/ProgramLink'),
  {
    fallback: (
      <div className="loadable-loader program-link-loader">
        <Loader />
      </div>
    ),
  }
);

const ProgramListButton = loadable(
  () => import('./components/ProgramListButton/ProgramListButton'),
  {
    fallback: (
      <div className="loadable-loader program-link-loader">
        <Loader />
      </div>
    ),
  }
);

const Story = loadable(() => import('./components/Story/Story'), {
  fallback: (
    <div className="loadable-loader story-loader">
      <Loader />
    </div>
  ),
});

const Interview = loadable(() => import('./components/Interview/Interview'), {
  fallback: (
    <div className="loadable-loader interview-loader">
      <Loader />
    </div>
  ),
});

const ProfileVideo = loadable(
  () =>
    import('./components/ProgramApp/Entry/Content/ProfileVideo/ProfileVideo'),
  {
    fallback: (
      <div className="loadable-loader profile-video-loader">
        <Loader />
      </div>
    ),
  }
);

const VideoGallery = loadable(
  () =>
    import('./components/ProgramApp/Entry/Content/VideoGallery/VideoGallery'),
  {
    fallback: (
      <div className="loadable-loader video-gallery-loader">
        <Loader />
      </div>
    ),
  }
);

const Newsletter = loadable(
  () => import('./components/Newsletter/Newsletter'),
  {
    fallback: (
      <div className="loadable-loader newsletter-loader">
        <Loader />
      </div>
    ),
  }
);

const SmallEntries = loadable(
  () => import('./components/SmallEntries/SmallEntries'),
  {
    fallback: (
      <div className="loadable-loader small-entries-loader">
        <Loader />
      </div>
    ),
  }
);

const ActorSlider = loadable(
  () => import('./components/ActorSlider/ActorSlider'),
  {
    fallback: (
      <div className="loadable-loader actor-slider-loader">
        <Loader />
      </div>
    ),
  }
);

const PlayCarousel = loadable(
  () => import('./components/PlayCarousel/PlayCarousel'),
  {
    fallback: (
      <div className="loadable-loader play-carousel-loader">
        <Loader />
      </div>
    ),
  }
);

const Facade = loadable(() => import('./components/Facade/Facade'), {
  fallback: (
    <div className="loadable-loader facade-loader">
      <Loader />
    </div>
  ),
});

const NewsletterPage = loadable(
  () => import('./components/NewsletterPage/NewsletterPage'),
  {
    fallback: (
      <div className="loadable-loader newsletter-page-loader">
        <Loader />
      </div>
    ),
  }
);

// Start registering  react component on custom tag names

export default () => {
  registerReact('menu-component', Menu);
  registerReact('cookie-consent-component', CookieConsent);
  registerReact('gallery-component', Gallery);
  registerReact('play-header-component', PlayHeader);
  registerReact('newsletter-panel-component', NewsletterPanel);
  registerReact('event-ticker-component', EventTicker);
  registerReact('ticket-list-component', TicketList);
  registerReact('show-sub-menu-component', ShowSubMenu);
  registerReact('actors-component', Actors);
  registerReact('event-calendar-component', EventCalendar);
  registerReact('loader-component', Loader);
  registerReact('share-component', Share);
  registerReact('vimeo-component', Vimeo);
  registerReact('youtube-component', Youtube);
  registerReact('video-component', Video);
  registerReact('video-simple-component', VideoSimple);
  registerReact('video-background-component', VideoBackground);
  registerReact('related-content-component', RelatedContent);
  registerReact('ensemble-actors-component', EnsembleActors);
  registerReact('ensemble-band-component', EnsembleBand);
  registerReact('ensemble-artistic-component', EnsembleArtistic);
  registerReact('ensemble-contributors-component', EnsembleContributors);
  registerReact('article-list-component', ArticleList);
  registerReact('alert-component', Alert);
  registerReact('insight-component', Insight);
  registerReact('video-stream-component', VideoStream);
  registerReact('popup-component', Popup);
  registerReact('profile-tickets-component', ProfileTickets);
  registerReact('profile-stream-component', ProfileStream);
  registerReact('stream-countdown-link-component', StreamCountdownLink);
  registerReact('program-link-component', ProgramLink);
  registerReact('program-list-button', ProgramListButton);
  registerReact('story-component', Story);
  registerReact('interview-component', Interview);
  registerReact('profile-video-component', ProfileVideo);
  registerReact('video-gallery-component', VideoGallery);
  registerReact('newsletter-component', Newsletter);
  registerReact('small-entries-component', SmallEntries);
  registerReact('actor-slider', ActorSlider);
  registerReact('play-carousel', PlayCarousel);
  registerReact('facade-component', Facade);
  registerReact('newsletter-page-component', NewsletterPage);

  // Finish registering react components

  picturefill();
  objectFitImages();
  mobileButton();
};
