/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useRef, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useInview from 'utils/inview';
import './Video.scss';

/**
 * This is the Video component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
const Video = ({ src, type, controlPosition, labels }) => {
  const containerRef = useRef(null);
  const videoRef = useRef(null);
  const [muted, setMuted] = useState(false);
  const [ready, setReady] = useState(false);
  const [play, setPlay] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [portrait, setPortrait] = useState(false);
  const inview = useInview(videoRef, {
    threshold: 0.25,
  });

  const onResize = debounce(() => {
    if (videoRef.current) {
      videoRef.current.style.width = '100%';
      videoRef.current.style.height = 'auto';

      const isPortrait =
        containerRef.current.offsetHeight > videoRef.current.offsetHeight;

      videoRef.current.style.width = '';
      videoRef.current.style.height = '';

      setPortrait(isPortrait);
    }
  }, 60);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return function cleanup() {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.loop = true;
    }

    return function cleanup() {
      if (videoRef.current) {
        videoRef.current.muted = true;
        videoRef.current.loop = false;
        videoRef.current.pause();
      }
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      if (inview) {
        if (play) {
          videoRef.current.play();
        }
      } else {
        videoRef.current.pause();
      }
    }
  }, [inview]);

  useEffect(() => {
    if (videoRef.current) {
      if (play) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [play]);

  function togglePlay() {
    setPlay(!play);
  }

  function renderStartButton() {
    if (!ready) return null;
    if (playing) return null;

    return (
      <button
        className="icon-icon-play play"
        aria-label={labels.play}
        onClick={togglePlay}
      />
    );
  }

  function renderControls() {
    if (!ready) return null;

    return (
      <div className="video-controls" style={controlPosition}>
        <button
          className={classnames('video-toggle', {
            'icon-icon-pause': playing,
            'icon-icon-play': !playing,
            playing,
          })}
          type="button"
          aria-label={playing ? labels.pause : labels.play}
          onClick={togglePlay}
        />
        <button
          className={classnames('video-sound', {
            'icon-icon-sound-off': muted,
            'icon-icon-sound-on': !muted,
          })}
          type="button"
          aria-label={muted ? labels.unmute : labels.mute}
          onClick={() => {
            videoRef.current.muted = !muted;
          }}
        />
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      className={classnames('video-container', { portrait })}
    >
      <video
        ref={videoRef}
        playsInline
        onCanPlay={() => {
          setReady(true);
        }}
        onPlay={() => {
          setPlaying(true);
        }}
        onPause={() => {
          setPlaying(false);
        }}
        onVolumeChange={({ target }) => {
          setMuted(target.muted);
        }}
      >
        <source src={src} type={type} />
      </video>
      {renderControls()}
      {renderStartButton()}
    </div>
  );
};

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Video.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  controlPosition: PropTypes.object,
  labels: PropTypes.shape({
    play: PropTypes.string.isRequired,
    pause: PropTypes.string.isRequired,
    mute: PropTypes.string.isRequired,
    unmute: PropTypes.string.isRequired,
  }).isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Video.defaultProps = {
  type: 'video/mp4',
  controlPosition: null,
  labels: {
    play: 'Play video',
    pause: 'Pause video',
    mute: 'Mute sound',
    unmute: 'Unmute sound',
  },
};

export default Video;
