/* global gsap */

import isEqual from 'lodash/isEqual';

class Stagger {
  constructor(node, options = {}) {
    this.props = {
      name: 'stagger',
      rootMargin: '0px 0px 0px 0px',
      threshold: 1.0,
      selector: null,
      delay: 0,
      duration: 1.2,
      stagger: 0.5,
      ...options,
    };
    this.node = node;

    /* prevent double decoration */
    if (this.node.decorator) {
      if (this.node.decorator instanceof Stagger) {
        this.node.decorator.destroy();
      }
    }

    this.node.decorator = this;
    this.state = {
      inview: false,
      complete: false,
    };

    this.items = [];
    this.tween = null;
    this.timeout = null;

    if (this.props.selector) {
      this.items = this.node.querySelectorAll(this.props.selector);
    }

    if (this.items.length) {
      this.create();
    }
  }

  create = () => {
    const { stagger, delay, name, duration } = this.props;

    this.tween = gsap.from(this.items, {
      stagger: {
        duration,
        each: stagger,
        ease: `steps(${this.items.length})`,
        onStart: function() {
          const target = this.targets().pop();
          target.classList.add(`${name}-enter-active`);
        },
      },
      paused: true,
      delay,
      onComplete: () => {
        this.setState({ complete: true });
      },
    });
    this.registerObserver();
    this.render();
  };

  destroy = () => {
    this.observer.unobserve(this.node);

    Array.from(this.items).forEach(node => {
      node.classList.remove(`${this.props.name}-enter`);
      node.classList.remove(`${this.props.name}-enter-active`);
    });

    this.node.decorator = null;
  };

  setState = state => {
    const preState = { ...this.state };

    this.state = {
      ...this.state,
      ...state,
    };

    if (!isEqual(this.state, preState)) {
      this.render();
    }
  };

  registerObserver = () => {
    const { rootMargin, threshold } = this.props;
    this.observer = new IntersectionObserver(this.onIntersection, {
      rootMargin,
      threshold,
    });
    this.observer.observe(this.node);
  };

  onIntersection = entries => {
    entries.forEach(({ intersectionRatio }) => {
      if (intersectionRatio > 0) {
        this.setState({ inview: true });
      }
    });
  };

  render = () => {
    const { inview, complete } = this.state;
    clearTimeout(this.duration);
    if (complete) {
      this.timeout = setTimeout(() => {
        Array.from(this.items).forEach(node => {
          node.classList.remove(`${this.props.name}-enter`);
          node.classList.remove(`${this.props.name}-enter-active`);
        });
      }, this.props.duration);
    } else {
      Array.from(this.items).forEach(node => {
        node.classList.add(`${this.props.name}-enter`);
      });
    }

    if (inview && !complete) {
      this.tween.play();
    }
  };
}

export default (node, props) => new Stagger(node, props);
