/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the Cart component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Cart({ title }) {
  return (
    <span className="svg svg-square svg-cart">
      <span>
        <svg
          role="img"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
        >
          <title>{title}</title>
          <path fillRule="evenodd" clipRule="evenodd" d="M3,2h3l1,2H3V2z" />
          <circle cx="8" cy="20" r="2" />
          <circle cx="17" cy="20" r="2" />
          <path fillRule="evenodd" clipRule="evenodd" d="M5,4h15l-2,8H9L5,4z" />
          <rect x="6" y="14" width="13" height="2" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9,11h3l-4,4l-2-1L9,11z"
          />
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Cart.propTypes = {
  title: PropTypes.string.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Cart.defaultProps = {
  title: 'Cart',
};

export default Cart;
