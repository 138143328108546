/* global TweenMax */
import FontFaceObserver from 'fontfaceobserver';
import SplitText from 'gsap/SplitText';

const fontObserver = new FontFaceObserver('Cormorant Garamond', {
  weight: 400,
});

class StaggerText {
  constructor(node, options = {}) {
    this.props = {
      rootMargin: '0px 0px 0px 0px',
      threshold: 1.0,
      duration: 1,
      ...options,
    };

    this.node = node;

    /* prevent double decoration */
    if (this.node.decorator) {
      if (this.node.decorator instanceof StaggerText) {
        this.node.decorator.destroy();
      }
    }

    this.node.decorator = this;
    this.node.setAttribute('initialized', 'true');
    this.original = this.node.innerHTML;

    fontObserver.load().then(this.create);
  }

  create = () => {
    const { rootMargin, threshold } = this.props;
    this.text = new SplitText(this.node, { type: 'words' });

    this.tween = TweenMax.fromTo(
      this.text.words,
      this.props.duration,
      { opacity: 0 },
      {
        opacity: 1,
        ease: 'power1.inOut',
        stagger: 0.1,
        paused: true,
      }
    );

    this.observer = new IntersectionObserver(this.onIntersection, {
      rootMargin,
      threshold,
    });

    this.observer.observe(this.node);
  };

  destroy = () => {
    this.tween.kill();
    this.text.revert();
    this.observer.unobserve(this.node);
    this.node.decorator = null;
  };

  execute = () => {
    this.tween.eventCallback('onComplete', this.destroy);
    this.tween.play();
  };

  onIntersection = entries => {
    entries.forEach(({ intersectionRatio }) => {
      if (intersectionRatio > 0) {
        this.execute();
      }
    });
  };
}

export default (node, props) => new StaggerText(node, props);
