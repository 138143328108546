import scrollTo from 'utils/scrollTo';

class ToTop {
  name = 'totop';
  constructor(node, options = {}) {
    this.props = {
      ...options,
    };

    this.node = node;
    this.timeout = null;
    this.prev = 0;

    if (!this.node.decorator || this.node.decorator.name !== 'totop') {
      this.node.decorator = this;
      this.create();
    }
  }

  create = () => {
    this.node.addEventListener('click', this.onClick);
    window.addEventListener('scroll', this.onScroll);
    this.onScroll();
  };

  destroy = () => {
    this.node.removeEventListener('click', this.onClick);
    window.removeEventListener('scroll', this.onScroll);
  };

  onClick = () => {
    scrollTo(document.body);
  };

  onScroll = () => {
    clearTimeout(this.timeout);
    const currentScroll =
      document.documentElement.scrollTop || document.body.scrollTop;

    const up = currentScroll - this.prev < 0;

    if (currentScroll > 120) {
      if (up) {
        this.node.classList.add('visible');
      } else {
        this.node.classList.remove('visible');
      }
    } else {
      this.node.classList.remove('visible');
    }

    this.prev = currentScroll;
  };
}

export default (node, props) => new ToTop(node, props);
