/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the Hamburger component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Hamburger({ title, ...props }) {
  return (
    <span className="svg svg-square svg-hamburger">
      <span>
        <svg
          {...props}
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0"
          y="0"
          viewBox="0 0 25 25"
          enableBackground="new 0 0 25 25"
          xmlSpace="preserve"
        >
          <title>{title}</title>
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="square"
            d="M6 7.8h13M6 12.5h13M6 17.2h13"
          />
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Hamburger.propTypes = {
  title: PropTypes.string.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Hamburger.defaultProps = {
  title: 'Menu',
};

export default Hamburger;
