import { useRef, useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
export default (settings = {}) => {
  const ref = useRef(null);
  const [inview, setInview] = useState(false);

  const { root, rootMargin, threshold } = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
    ...settings,
  };

  const [observer, setObserver] = useState(null);

  function useDeepCompareMemoize(value) {
    const ref = useRef();

    if (!isEqual(value, ref.current)) {
      ref.current = value;
    }

    return ref.current;
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(({ target, isIntersecting }) => {
          if (target === ref.current) {
            setInview(isIntersecting);
          }
        });
      },
      {
        root,
        rootMargin,
        threshold,
      }
    );

    setObserver(observer);

    return function cleanup() {
      observer.disconnect();
      setObserver(null);
    };
  }, [useDeepCompareMemoize({ root, rootMargin, threshold })]); //eslint-disable-line

  useEffect(() => {
    const { current } = ref;
    if (observer) {
      if (current && observer) {
        observer.observe(current);
      }
    }
    return function cleanup() {
      if (observer) {
        if (current && observer) {
          observer.unobserve(current);
        }
      }
    };
  }, [ref, observer]);

  return [ref, inview];
};
