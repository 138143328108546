import setDefaultOptions from 'date-fns/setDefaultOptions';
import nb from 'date-fns/locale/nb';
import en from 'date-fns/locale/en-GB';
import nn from 'locale/nn/index';

export function initLang() {
  let locale = en;
  switch (document.documentElement.lang) {
    case 'nn':
      locale = nn;
      break;
    case 'nb':
      locale = nb;
      break;

    default:
      locale = en;
      break;
  }

  setDefaultOptions({
    locale,
  });
}

export default initLang;
