/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CloseIcon from 'components/Icons/Exit/Exit';
import TicketIcon from 'components/Icons/Ticket/Ticket';
import ProfileIcon from 'components/Icons/Profile/Profile';
import ProgramIcon from 'components/Icons/Program/Program';
import EpicenterIcon from 'components/Icons/Epicenter/Epicenter';
import GiftIcon from 'components/Icons/Gift/Gift';
import useMount from 'effects/mount';
import useKeys from 'effects/keys';
import Submenu from './Submenu/Submenu';
import './Featured.scss';

const letters = 'abcdefghijklmnopqrstuvwxyzæøå'.split('');

/**
 * This is the Featured component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */

const Featured = ({ open, items, labels, onClose }) => {
  const container = useRef(null);
  const list = useRef(null);
  const mount = useMount();
  const [current, setCurrent] = useState(0);
  const [active, setActive] = useState(null);
  const letterKeys = {};

  function onLetter({ key }) {
    let index = items.findIndex(({ title }) => {
      return title[0].toLowerCase() === key;
    }, current + 1);

    if (index === -1) {
      index = items.findIndex(({ title }) => {
        return title[0].toLowerCase() === key;
      });
    }

    if (index !== -1) {
      setCurrent(index);
    }
  }

  letters.forEach(letter => {
    letterKeys[letter] = onLetter;
  });

  useKeys('featured', open && !active, {
    esc: onClose,
    up: e => {
      e.preventDefault();
      setCurrent(prev => {
        const next = prev - 1;
        if (next < 0) {
          return items.length - 1;
        }

        return next;
      });
    },
    down: e => {
      e.preventDefault();
      setCurrent(prev => {
        const next = prev + 1;
        if (next >= items.length) {
          return 0;
        }

        return next;
      });
    },
    home: e => {
      e.preventDefault();
      setCurrent(0);
    },
    end: e => {
      e.preventDefault();
      setCurrent(items.length - 1);
    },
    ...letterKeys,
  });

  useEffect(() => {
    if (open && !active) {
      const item = container.current.querySelector(
        `.top-level > li:nth-child(${current +
          1}) > button, .top-level > li:nth-child(${current + 1}) > a`
      );
      if (item) {
        item.focus();
      }
    }
  }, [open, active, current]);

  useEffect(() => {
    if (open) {
      document.body.classList.add('menu-open');
    }

    return function cleanup() {
      document.body.classList.remove('menu-open');
    };
  }, [open]);

  useEffect(() => {
    if (!open) {
      setActive(null);
      setCurrent(0);
    }
  }, [open]);

  useEffect(() => {
    if (!mount && !open) {
      const menuButtonId = container.current.getAttribute('aria-labelledby');
      const menuButton = document.getElementById(menuButtonId);
      if (menuButton) {
        menuButton.focus();
      }
    }
  }, [mount, open]);

  const onContainerClick = ({ target }) => {
    if (target === container.current) {
      onClose();
    }
  };

  function renderIcon(className) {
    if (className) {
      if (className.indexOf('ticket') !== -1) {
        return <TicketIcon />;
      }

      if (className.indexOf('user') !== -1) {
        return <ProfileIcon />;
      }

      if (className.indexOf('profile') !== -1) {
        return <ProfileIcon />;
      }

      if (className.indexOf('program') !== -1) {
        return <ProgramIcon />;
      }

      if (className.indexOf('stream') !== -1) {
        return <EpicenterIcon />;
      }

      if (className.indexOf('gift') !== -1) {
        return <GiftIcon />;
      }
    }

    return <span className="default" />;
  }

  return (
    <nav
      ref={container}
      id="submenu"
      aria-labelledby="menubutton"
      className="featured-navigation"
      onClick={onContainerClick}
    >
      <div>
        <button
          className="exit"
          tabIndex={open ? null : -1}
          onClick={onClose}
          aria-label={labels.close}
        >
          <span>
            <CloseIcon title={labels.close} />
            <span className="text">{labels.close}</span>
          </span>
        </button>
        <ul ref={list} className="top-level" role="none">
          {items.map(({ key, className, title, children, ...props }, index) => {
            if (children) {
              return (
                <li key={key} role="none">
                  <button
                    className={classnames({ expanded: key === active })}
                    aria-controls={`sub${key}`}
                    aria-expanded={active === key}
                    aria-label={title}
                    tabIndex={open ? null : -1}
                    onClick={() => {
                      setActive(key === active ? null : key);
                      setCurrent(index);
                    }}
                  >
                    <span>
                      <span className="toggle" />
                      <span
                        className="text"
                        dangerouslySetInnerHTML={{ __html: title }}
                      />
                    </span>
                  </button>
                  <Submenu
                    id={`sub${key}`}
                    items={children}
                    open={active === key}
                    onOpen={() => {
                      setActive(index);
                    }}
                    onClose={() => {
                      setActive(null);
                    }}
                  />
                </li>
              );
            }

            return (
              <li key={key} role="none">
                <a {...props} tabIndex={open ? null : -1}>
                  {renderIcon(className)}
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Featured.propTypes = {
  open: PropTypes.bool,
  items: PropTypes.array,
  labels: PropTypes.shape({
    close: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Featured.defaultProps = {
  open: false,
  items: [],
  labels: {
    close: 'Close',
  },
  onClose: () => {},
};

export default Featured;
