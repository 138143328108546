/*
 * This file is part of the Det Norske Teatret Nettside 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Arrow.scss';

/**
 * This is the Arrow component.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @return {JSX}
 */
function Arrow({ title, left, right, up, down, ...props }) {
  return (
    <span
      {...props}
      className={classnames('svg svg-square svg-arrow', {
        left,
        right,
        up,
        down,
      })}
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0"
          y="0"
          viewBox="0 0 25 25"
          enableBackground="new 0 0 25 25"
          xmlSpace="preserve"
          aria-hidden="true"
        >
          <title>{title}</title>
          <g transform="translate(-465.000000, -311.000000)">
            <g transform="translate(464.000000, 310.000000)">
              <polygon
                fillRule="evenodd"
                clipRule="evenodd"
                points="15.3 13.8 8.8 20.4 8.8 24.1 19.1 13.8 8.8 2.9 8.8 6.6 "
              />
            </g>
          </g>
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Arrow.propTypes = {
  title: PropTypes.string.isRequired,
  left: PropTypes.bool,
  right: PropTypes.bool,
  up: PropTypes.bool,
  down: PropTypes.bool,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Arrow.defaultProps = {
  title: 'Arrow',
  left: false,
  right: false,
  up: false,
  down: false,
};

export default Arrow;
