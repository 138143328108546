import queryString from 'query-string';

export const BASE_URL = '';

/**
 * Creates request object and returns a fetch promise.
 *
 * @author Thomas Sømoen <thomas@apt.no>
 *
 * @param {string} endpoint
 * @param {string} method
 * @param {Object} body
 *
 * @return {Promise}
 */
function callApi(endpoint, method, body) {
  const { Headers, fetch } = window;

  const request = {
    method: method || 'GET',
    credentials: 'same-origin',
    headers: new Headers(),
  };

  request.headers.append('X-Requested-With', 'XMLHttpRequest');
  request.headers.append('Content-Type', 'application/json');
  request.headers.append('Accept', 'application/json');

  if (body) {
    if (typeof body !== 'object') {
      return new Promise((resolve, reject) =>
        reject('Body must be an object.')
      );
    }

    switch (method) {
      case 'PUT':
      case 'POST':
        request.body = JSON.stringify(body);
        break;

      case 'GET':
      case 'DELETE':
        endpoint += `?${queryString.stringify(body)}`;
        break;

      default:
      // no-op
    }
  }

  return fetch(BASE_URL + endpoint, request)
    .then(response => {
      if (response.status === 404 || response.status === 500) {
        return new Promise((resolve, reject) => {
          response
            .json()
            .then(json => {
              reject({
                status: response.status,
                ...json,
              });
            })
            .catch(() => {
              reject({
                status: response.status,
                error: response.statusText,
              });
            });
        });
      }

      return response;
    })
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok || json.status === 'error') {
        return Promise.reject({
          status: response.status,
          ...json,
        });

        // return new Promise((resolve, reject) =>
        //   reject({
        //     status: response.status,
        //     ...json,
        //   })
        // );
      }

      return json;
    })
    .catch(error => {
      if (error.status) {
        return Promise.reject(error);
      }
      return Promise.reject({ status: 500, error: error.toString() });
    });
}

export default callApi;
