import globalHook from 'use-global-hook';

let busy = false;

const initialState = {
  name: null,
  value: null,
  ...window.initialCSRF,
};

const actions = {
  refresh: store => {
    if (!busy) {
      busy = true;
      fetch('/actions/blitz/csrf/token')
        .then(result => {
          return result.text();
        })
        .then(value => {
          store.setState({ value });
          busy = false;
        })
        .catch(() => {
          busy = false;
        });
    }
  },
};

export default globalHook(initialState, actions);
